<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.2321 5.14416C20.3444 5.24035 20.4365 5.35772 20.5034 5.48955C20.5702 5.62139 20.6105 5.7651 20.6217 5.91249C20.633 6.05987 20.6152 6.20803 20.5691 6.34851C20.5231 6.48898 20.4499 6.61901 20.3536 6.73116L11.3536 17.2312C11.2527 17.3489 11.1286 17.4445 10.989 17.5119C10.8494 17.5794 10.6973 17.6173 10.5424 17.6232C10.3875 17.6291 10.233 17.6029 10.0886 17.5463C9.94429 17.4897 9.81321 17.4038 9.70362 17.2942L4.45362 12.0442C4.24879 11.8319 4.13553 11.5477 4.13823 11.2527C4.14093 10.9577 4.25938 10.6756 4.46806 10.4671C4.67675 10.2586 4.95897 10.1405 5.25394 10.138C5.54892 10.1356 5.83304 10.2491 6.04512 10.4542L10.4371 14.8447L18.6466 5.26716C18.8408 5.04094 19.1169 4.90107 19.4142 4.87829C19.7115 4.85551 20.0057 4.95018 20.2321 5.14416Z"
      fill="#29CC7A"
    />
  </svg>
</template>

<script>
export default {
  name: "PricingCheck",
};
</script>

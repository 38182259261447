<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-3 mx-auto position-relative">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div>
                <!--Serial Number -->
                <div class="password-block mgb-24">
                  <label for="serialNumber" class="label-text"
                    >10-Digit Serial Number</label
                  >
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="form.serial_number"
                      class="form-control"
                      :class="{ 'border-deep-red': activation_code_error }"
                      :maxlength="12"
                      id="serialNumber"
                      @input="throwInvalidActivationCode"
                      @paste="throwInvalidActivationCode"
                      placeholder="e.g. W435-635-773"
                    />

                    <div
                      class="error-message deep-red cfont-12 mgt-1"
                      v-if="activation_code_error"
                    >
                      Please enter the Serial Number including the hyphens(-) as
                      well
                    </div>
                  </div>
                </div>

                <!--Phone number -->
                <div class="password-block mgb-24">
                  <label for="phoneNumber" class="label-text"
                    >Phone number</label
                  >
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model.number="form.phone_number"
                      class="form-control"
                      id="phoneNumber"
                      @input="extractNonNumbers"
                      @paste="extractNonNumbers"
                      :maxlength="11"
                      ref="phoneInput"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>

                <!-- SUBMIT BUTTON -->
                <paystack
                  :amount="form.amount"
                  :email="getEmailAddress"
                  :paystackkey="paystack_pub_key"
                  :reference="reference"
                  :callback="callback"
                  :close="close"
                  :embed="false"
                  class="position-absolute invisible"
                  ref="paystackBtn"
                >
                </paystack>

                <button
                  class="btn btn-block btn-primary mgt-16 w-100"
                  :disabled="isDisabled"
                  @click="checkSubscription"
                  ref="activateBtn"
                >
                  Continue
                </button>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import paystack from "vue-paystack";
import { PAYSTACK_PUBLIC_KEY } from "@/env";
import formCard from "@/shared/components/form-card";

export default {
  name: "activateDesktop",

  metaInfo: {
    title: "Activate Desktop",
  },

  components: {
    paystack,
    formCard,
  },

  computed: {
    isDisabled() {
      return this.form.serial_number.length === 12 &&
        (this.form.serial_number.match(/-/g) || []).length == 2 &&
        this.form.serial_number[4] === "-" &&
        this.form.serial_number[8] === "-" &&
        this.form.phone_number.length === 11
        ? false
        : true;
    },

    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    getEmailAddress() {
      return `${this.form.phone_number}@class54.com`;
    },
  },

  data() {
    return {
      page_data: {
        show_back_link: false,
        back_link_route: "",
        header: "Activate desktop software",
        description:
          "Copy the Serial number on the Software’s Activate software page and paste it below.",
        footer_text: "I want to download the Software",
        footer_link: "/jamb-software-download",
      },

      paystack_pub_key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY_PROD,

      form: {
        serial_number: "",
        phone_number: "",
        amount: 200000,
      },

      activation_code_error: false,
    };
  },

  methods: {
    ...mapActions({
      activateDesktop: "storeSubscription/activateDesktop",
      checkDesktopSubscription: "storeSubscription/checkDesktopSubscription",
    }),

    throwInvalidActivationCode() {
      if (this.form.serial_number.length > 8) {
        this.activation_code_error =
          (this.form.serial_number.match(/-/g) || []).length == 2 &&
          this.form.serial_number[4] === "-" &&
          this.form.serial_number[8] === "-"
            ? false
            : true;
      }
    },

    extractNonNumbers() {
      let phone = this.$refs.phoneInput;
      this.form.phone_number = phone.value.replace(/[^0-9]/gi, "");
    },

    checkSubscription() {
      this.$refs.activateBtn.innerText = "Please wait ...";

      let payload = {
        serial: this.form.serial_number,
        phone_no: `234${this.form.phone_number.substring(1)}`,
      };

      this.checkDesktopSubscription(payload)
        .then((response) => {
          // console.log(response);

          if (response.data) {
            this.$bus.$emit("show-page-loader");

            setTimeout(() => {
              this.$bus.$emit("hide-page-loader");

              this.$router.push({
                name: "DesktopActivated",
                params: { activation_code: response.data.activation_code },
              });
            }, 2000);
          }
          // CALL PAYSTACK
          else this.$refs.paystackBtn.$el.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    callback(response) {
      let payload = {
        serial: this.form.serial_number,
        phone_no: `234${this.form.phone_number.substring(1)}`,
        amount: (this.form.amount / 100).toString(),
        transaction_ref: this.reference,
      };

      if (response.status === "success") {
        this.$bus.$emit("show-page-loader");

        this.activateDesktop(payload).then((res) => {
          if (res.status_code === 200) {
            this.$bus.$emit("hide-page-loader");

            this.$router.push({
              name: "DesktopActivated",
              params: { activation_code: res.activation_code },
            });
          }
          // FAILED RESPONSE
          else {
            this.$bus.$emit("hide-page-loader");
            this.$router.push({ name: "DesktopError" });
          }
        });
      }

      // FAILED TRANSACTION
      else {
        setTimeout(() => {
          this.$bus.$emit("hide-page-loader");
          this.$router.push({ name: "DesktopError" });
        });
      }
    },

    close() {
      console.log("Payment closed");
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="cta-block-one brand-white-bg">
    <div class="container px-3 px-sm-3 px-md-3 px-xl-2">
      <div class="block-content mx-auto">
        <!-- LEFT SECTION  -->
        <div class="left-section">
          <img
            v-lazy="staticAsset('MobileScreen.png', 'landing')"
            alt=""
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          />
        </div>

        <!-- RIGHT SECTION  -->
        <div class="right-section pdt-10">
          <!-- TITLE TEXT  -->
          <div
            class="title-text font-weight-700 brand-dark-blue"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            Prepare your way to success right from your mobile device.
          </div>

          <!-- META TEXT  -->
          <div
            class="meta-text brand-dark-blue"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="90"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            On both Android and iOS platforms, you will get a personalised and
            portable examination preparation experience which increases your
            chance of success by a very wide margin.
          </div>

          <!-- DOWNLOAD CTA ROW  -->
          <div
            class="btn-row"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="120"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <!-- ANDROID DOWNLOAD  -->
            <a
              href="https://play.google.com/store/apps/details?id=com.class54.mobile"
              target="_blank"
              class="custom-app-btn rounded-50 mgr-24"
            >
              <img
                v-lazy="staticAsset('Playstore.svg', 'landing')"
                alt="Google Playstore"
                class="icon-img"
              />

              <div>
                <div class="text-one text-uppercase cfont-10">Get it on</div>
                <div class="text-two">Google Play</div>
              </div>
            </a>

            <!-- DESKTOP DOWNLOAD  -->
            <a
              href="https://apps.apple.com/us/app/class54/id1622971742"
              target="_blank"
              class="custom-app-btn rounded-50"
            >
              <div class="icon icon-apple"></div>

              <div>
                <div class="text-one">Download on the</div>
                <div class="text-two">App Store</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ctaBlockOne",
};
</script>

<style lang="scss" scoped>
.cta-block-one {
  padding: toRem(70) 0 toRem(40);

  .block-content {
    @include flex-row-between-nowrap;
    align-items: flex-start;

    @include breakpoint-down(md) {
      @include flex-row-between-wrap;
    }

    .left-section {
      width: 50%;

      @include breakpoint-down(md) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .right-section {
      width: 50%;

      @include breakpoint-down(xl) {
        margin-bottom: toRem(15);
      }

      @include breakpoint-down(md) {
        width: 100%;
      }

      .title-text {
        @include font-height(46, 60);
        margin-bottom: toRem(20);
        text-align: left;
        width: 95%;

        @include breakpoint-down(xl) {
          @include font-height(38, 45);
        }

        @include breakpoint-custom-down(850) {
          @include font-height(32, 40);
        }

        @include breakpoint-down(sm) {
          width: 100%;
        }

        @include breakpoint-down(xs) {
          @include font-height(28, 36);
          text-align: center;
        }
      }

      .meta-text {
        @include font-height(16, 28);
        margin-bottom: toRem(48);
        width: 95%;

        @include breakpoint-down(xl) {
          @include font-height(15, 24);
          width: 94%;
        }

        @include breakpoint-down(sm) {
          width: 100%;
        }

        @include breakpoint-down(xs) {
          text-align: center;
        }
      }

      .btn-row {
        @include flex-row-start-nowrap;

        @include breakpoint-down(xs) {
          @include flex-row-center-wrap;
          width: 100%;
        }

        @include breakpoint-custom-down(390) {
          @include flex-column-center;
        }

        .custom-app-btn {
          @include breakpoint-custom-down(390) {
            margin-right: 0 !important;
            margin-bottom: toRem(15);
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="
      new-exam-card
      rounded-24
      brand-white-bg
      border-brand-border-grey
      w-100
    "
  >
    <!-- LEFT SECTION -->
    <div class="left-section">
      <div class="exam-image rounded-circle" :class="getBgGradient">
        <div class="place-center icon" v-if="exam_info.icon">
          <div :class="exam_info.icon"></div>
        </div>

        <div class="place-center img-icon" v-if="exam_info.icon_img">
          <img
            v-lazy="staticAsset(exam_info.icon_img, 'landing')"
            alt=""
            class="select-none"
          />
        </div>

        <div class="place-center icon-text brand-white font-weight-700" v-else>
          {{ exam_info.icon_text }}
        </div>
      </div>
    </div>

    <!-- RIGHT SECTION -->
    <div class="right-section w-100">
      <!-- TITLE TEXT -->
      <div class="header-text font-weight-700 blackish-grey">
        {{ exam_info.text }}
      </div>

      <!-- META TEXT -->
      <div class="description-text light-dark-text">
        {{ exam_info.description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newExamCard",

  props: {
    exam_info: {
      type: Object,
      default: () => ({
        icon: "",
        icon_text: "",
        icon_img: "",
        text: "JAMB",
        description:
          "Practise with over 20 years of UTME past questions and standard questions, detailed answers and explanation in real exam conditions.",
      }),
    },
  },

  computed: {
    getBgGradient() {
      if (this.exam_info.icon) return "yellow-gradient";
      else if (this.exam_info.icon_text) return "green-gradient";
      else if (this.exam_info.icon_img) return "lime-gradient";
      else return;
    },
  },
};
</script>

<style lang="scss">
.new-exam-card {
  padding: toRem(40) toRem(30);
  @include flex-row-start-nowrap;
  align-items: flex-start;

  @include breakpoint-down(xl) {
    padding: toRem(35) toRem(25);
  }

  @include breakpoint-down(xs) {
    @include flex-row-start-wrap;
    padding: toRem(30) toRem(20);
  }

  .left-section {
    margin-right: toRem(24);

    @include breakpoint-down(xl) {
      margin-right: toRem(17);
    }

    @include breakpoint-down(xs) {
      margin-right: 0;
      margin-bottom: toRem(10);
    }

    .exam-image {
      position: relative;
      @include square-shape(80);

      @include breakpoint-down(xl) {
        @include square-shape(75);
      }

      @include breakpoint-down(xs) {
        @include square-shape(70);
      }

      .place-center {
        @include center-placement;
      }

      .icon {
        font-size: toRem(44);

        @include breakpoint-down(xl) {
          font-size: toRem(33);
        }
      }

      .img-icon {
        img {
          width: toRem(42);
          height: auto;
        }
      }

      .icon-text {
        font-size: toRem(26);
        font-family: "Source Sans Pro" !important;

        @include breakpoint-down(xl) {
          font-size: toRem(22);
        }
      }
    }
  }

  .right-section {
    .header-text {
      @include font-height(32, 45);

      @include breakpoint-down(xl) {
        @include font-height(22, 40);
      }

      @include breakpoint-down(xs) {
        @include font-height(26, 40);
      }
    }

    .description-text {
      @include font-height(15.5, 25);

      @include breakpoint-down(xl) {
        @include font-height(14.75, 24);
      }
    }
  }
}

.green-gradient {
  background: radial-gradient(
    102.68% 102.68% at 58.04% 106.25%,
    #239621 0%,
    #bae706 100%
  );
}

.yellow-gradient {
  background: linear-gradient(160.59deg, #faeb07 13.48%, #ffdf6f 58.78%);
}

.lime-gradient {
  background: linear-gradient(0deg, #eeffe5, #eeffe5),
    linear-gradient(323.71deg, rgba(27, 164, 95, 0) 64.1%, #deffee 153.04%);
}
</style>

<template>
  <ModalCover
    @closeModal="$emit('closeTriggered')"
    modal_style="modal-sm-md"
    :show_close="true"
  >
    <!-- MODAL BODY -->
    <template slot="modal-cover-body">
      <div class="mobile-download-area w-100">
        <!-- ICON TOP -->
        <div class="icon-top mgb-17 mgt-5">
          <img
            v-lazy="staticAsset('DownloadIcon.png', 'landing')"
            alt="downloadGradelyDesktop"
            class="w-100 h-100"
          />
        </div>

        <div
          class="title-text font-weight-700 brand-dark-blue mgb-12 text-center"
        >
          Download our free mobile app
        </div>

        <div class="meta-text dark-text mgb-22 text-center">
          Download the Class54 mobile for
          <span class="font-weight-600">FREE</span> access to thousands of past
          questions, answers and detailed explanations
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <a
            href="https://bit.ly/3v0sChj"
            target="_blank"
            class="custom-app-btn rounded-50 mgb-20"
          >
            <img
              v-lazy="staticAsset('Playstore.svg', 'landing')"
              alt="Google Playstore"
              class="icon-img"
            />

            <div>
              <div class="text-one text-uppercase cfont-10-5">Get it on</div>
              <div class="text-two">Google Play</div>
            </div>
          </a>

          <a
            href="https://apps.apple.com/us/app/class54/id1622971742"
            target="_blank"
            class="custom-app-btn rounded-50"
          >
            <div class="icon icon-apple"></div>
            <div>
              <div class="text-one">Download on the</div>
              <div class="text-two">App Store</div>
            </div>
          </a>
        </div>
      </div>
    </template>
  </ModalCover>
</template>

<script>
import ModalCover from "@/shared/components/modal-cover";

export default {
  name: "mobileDownloadModal",

  components: {
    ModalCover,
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-download-area {
  position: relative;
  background: $brand-white;
  padding: toRem(60) toRem(125);
  @include flex-column-center;
  min-height: 46vh;
  height: auto;
  max-height: 70vh;

  @include breakpoint-down(md) {
    padding: toRem(60) toRem(90);
  }

  @include breakpoint-down(sm) {
    padding: toRem(50) toRem(52);
  }

  @include breakpoint-down(xs) {
    padding: toRem(50) toRem(28);
  }

  .icon-top {
    @include square-shape(50);
  }

  .title-text {
    @include font-height(19, 24.5);
  }

  .meta-text {
    @include font-height(15.75, 26);
    padding: 0 toRem(20);
  }
}
</style>

<template>
  <div class="subscription-section brand-white-bg" id="subscriptions">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="subscription-content mx-auto">
        <!-- TITLE TEXT  -->
        <div
          class="title-text font-weight-700 brand-dark-blue text-center mgb-4"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Subscription plans
        </div>

        <!-- META TEXT  -->
        <div
          class="meta-text brand-dark-blue text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="80"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Available subscription plans for Android and IOS
        </div>

        <!-- SUBSCRIPTION ROW  -->
        <div
          class="subscription-row"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="130"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <subscription-card
            :subscription="subscription"
            v-for="(subscription, index) in subscriptions"
            :key="index"
          />
        </div>

        <!-- INFO TEXT  -->
        <div
          class="info-text text-center brand-dark-blue"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-delay="140"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Reach us via info@class54.com or +234-701-2222-333 if you require
          entreprise or bulk plans
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subscriptionCard from "@/modules/landing/components/home-comps/subscription-card";

export default {
  name: "subscriptionSection",

  components: {
    subscriptionCard,
  },

  data: () => ({
    subscriptions: [
      {
        name: "basic",
        slug: "basic",
        amount: "1,500",
        duration: "1 month",
      },
      {
        name: "premium",
        slug: "premium",
        amount: "2,500",
        duration: "3 months",
      },
      {
        name: "platinum",
        slug: "platinum",
        amount: "4,000",
        duration: "1 year",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.subscription-section {
  padding: toRem(64) 0;

  .subscription-content {
    @include flex-column-center;

    .title-text {
      @include font-height(42, 54);

      @include breakpoint-down(xl) {
        @include font-height(40, 50);
      }

      @include breakpoint-custom-down(850) {
        @include font-height(34, 45);
      }

      @include breakpoint-down(xs) {
        @include font-height(28, 36);
      }
    }

    .meta-text {
      @include font-height(17, 25);
      margin-bottom: toRem(75);

      @include breakpoint-down(xl) {
        @include font-height(15, 22);
      }

      @include breakpoint-custom-down(850) {
        @include font-height(14.5, 21);
        margin-bottom: toRem(65);
      }

      @include breakpoint-down(md) {
        margin-bottom: toRem(60);
      }

      @include breakpoint-down(xs) {
        @include font-height(14, 20);
        margin-bottom: toRem(40);
      }
    }

    .subscription-row {
      @include flex-row-center-wrap;
      margin-bottom: toRem(64);
    }

    .info-text {
      @include font-height(14.25, 19);

      @include breakpoint-custom-down(850) {
        @include font-height(14, 18);
      }
    }
  }
}
</style>

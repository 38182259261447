<template>
  <div class="desktop-subscription">
    <desktop-download-card />

    <!-- FAQ SECTION -->
    <faq-section :faqs="faqs" />

    <!-- DESKTOP CTA -->
    <desktop-cta />
  </div>
</template>

<script>
import desktopDownloadCard from "@/modules/subscriptions/components/desktop/desktop-download-card";
import faqSection from "@/modules/landing/components/about-comps/faq-section";
import desktopCta from "@/modules/subscriptions/components/desktop/desktop-cta";

export default {
  name: "desktopSubscription",

  components: {
    desktopDownloadCard,
    faqSection,
    desktopCta,
  },

  data() {
    return {
      faqs: [
        {
          title: "How do I install the Class54 Desktop app?",
          value:
            "Click on the download button above and after the download is complete, click on the .exe file and follow the installation instruction that will be displayed on your screen.",
        },
        {
          title:
            "Can the Class54 JAMB/UTME software be installed on a Mac computer?",
          value:
            "For now, this software can only be installed on a windows PC.",
        },
        {
          title:
            "What is the minimum configuration for a computer that this software can be installed on?",
          value:
            "The software can only be installed on a Windows computer with a minimum of 4GB of RAM. The minimum operating system version is Windows 8 and above.",
        },
        {
          title: "How much does it cost to activate this software?",
          value:
            "This software goes for a token of NGN2,000. This gives you access to all the JAMB/UTME past questions, detailed explanations, practice analytics, and summarised recommended novels from the year 2000 to the present.",
        },
        {
          title: "How do I activate the software?",
          value:
            "There are two easy options for activating the software. You can either make a Bank transfer directly to our account and then call/text us to send you an activation code. Or you can follow this link to automatically generate your activation code by paying with a debit card.",
        },
        {
          title:
            "How long does the activation/subscription last after I have activated it?",
          value:
            "The activation is valid for a whole year. This is mostly because we update the app with new questions and also update the existing ones every year. We are also very confident that if you are using the app to prepare for JAMB/UTME in the current year, you wouldn’t need it next year because you would have passed excellently.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="faq-section" id="faq">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-3">
      <div class="faq-content mx-auto">
        <!-- SECTION TITLE  -->
        <div
          class="intro-text brand-dark-blue text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
        >
          Do you have any questions?
        </div>

        <!-- TITLE TEXT  -->
        <div
          class="title-text brand-dark-blue font-weight-700 text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="90"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
        >
          Frequently asked questions
        </div>

        <!-- FAQ CARDS  -->
        <div class="faq-wrapper">
          <faq-card
            v-for="(faq, index) in faqs"
            :key="index"
            :title="faq.title"
            :value="faq.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faqCard from "@/modules/landing/components/about-comps/faq-card";

export default {
  name: "faqSection",

  components: {
    faqCard,
  },

  props: {
    faqs: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-section {
  padding: toRem(64) 0;

  .faq-content {
    @include flex-column-center;

    .intro-text {
      @include font-height(17, 26);
      margin-bottom: toRem(16);

      @include breakpoint-down(lg) {
        @include font-height(15, 22);
      }
    }

    .title-text {
      @include font-height(42, 54);
      margin-bottom: toRem(48);
      width: 64%;

      @include breakpoint-down(lg) {
        @include font-height(35, 48);
        width: 70%;
      }

      @include breakpoint-down(sm) {
        @include font-height(28, 40);
        margin-bottom: toRem(38);
        width: 78%;
      }

      @include breakpoint-down(xs) {
        @include font-height(24, 36);
        margin-bottom: toRem(36);
        width: 100%;
      }
    }

    .faq-wrapper {
      width: 70%;

      @include breakpoint-down(lg) {
        width: 70%;
      }

      @include breakpoint-down(md) {
        width: 85%;
      }

      @include breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}
</style>

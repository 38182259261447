<template>
  <div class="about-hero-banner brand-sky-blue-bg">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="hero-content mx-auto">
        <img
          v-lazy="staticAsset('ContentState.png', 'landing')"
          alt="AboutClass54"
          class="about-banner"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        />

        <!-- TITLE TEXT  -->
        <div
          class="title-text text-center brand-dark-blue font-weight-700"
          data-aos="fade-up"
          data-aos-offset="150"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Comprehensive and affordable exam preparation for everyone
        </div>

        <!-- META TEXT  -->
        <div
          class="meta-text text-center brand-dark-blue"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="120"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Class54 is an all-in-one solution for students to prepare for exams
          quickly and efficiently. Our mission is to empower students by
          providing them with a quick revision guide, exam simulator, question
          engine and cloud-based analytics platform.
        </div>

        <!-- BUTTON ROW  -->
        <div
          class="btn-row"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="150"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <!-- ANDROID DOWNLOAD  -->
          <a
            href="https://play.google.com/store/apps/details?id=com.class54.mobile"
            target="_blank"
            class="custom-app-btn rounded-50"
          >
            <img
              v-lazy="staticAsset('Playstore.svg', 'landing')"
              alt="Google Playstore"
              class="icon-img"
            />

            <div>
              <div class="text-one text-uppercase cfont-10">Get it on</div>
              <div class="text-two">Google Play</div>
            </div>
          </a>

          <a
            href="https://apps.apple.com/us/app/class54/id1622971742"
            target="_blank"
            class="custom-app-btn rounded-50 mx-3"
          >
            <div class="icon icon-apple"></div>

            <div>
              <div class="text-one">Download on the</div>
              <div class="text-two">App Store</div>
            </div>
          </a>

          <!-- DESKTOP DOWNLOAD  -->
          <router-link
            class="custom-app-btn rounded-50"
            to="/jamb-software-download"
          >
            <div class="icon icon-microsoft"></div>

            <div>
              <div class="text-one">Download the</div>
              <div class="text-two">Windows Software</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutHeroBanner",
};
</script>

<style lang="scss" scoped>
.about-hero-banner {
  padding: toRem(140) 0;

  @include breakpoint-down(md) {
    padding: toRem(110) 0;
  }

  @include breakpoint-down(sm) {
    padding: toRem(80) 0;
  }

  .hero-content {
    @include flex-column-center;

    .about-banner {
      @include rectangle-shape(498, 350);
      margin-bottom: toRem(48);

      @include breakpoint-down(md) {
        @include rectangle-shape(420, 300);
        margin-bottom: toRem(45);
      }

      @include breakpoint-down(sm) {
        @include rectangle-shape(350, 250);
      }

      @include breakpoint-down(xs) {
        margin-bottom: toRem(40);
        min-width: unset;
        min-height: unset;
        width: 100%;
        height: auto;
      }
    }

    .title-text {
      @include font-height(42, 52);
      margin-bottom: toRem(16);
      width: 60%;

      @include breakpoint-down(lg) {
        @include font-height(38, 48);
        width: 70%;
      }

      @include breakpoint-down(md) {
        @include font-height(34, 42);
        width: 80%;
      }

      @include breakpoint-down(sm) {
        @include font-height(28, 38);
        width: 80%;
      }

      @include breakpoint-down(xs) {
        @include font-height(25, 33);
        width: 100%;
      }
    }

    .meta-text {
      @include font-height(17, 26);
      margin-bottom: toRem(48);
      width: 70%;

      @include breakpoint-down(md) {
        @include font-height(16, 26);
        width: 80%;
      }

      @include breakpoint-down(xs) {
        @include font-height(15, 25);
        width: 100%;
      }
    }

    .btn-row {
      @include flex-row-center-wrap;

      @include breakpoint-down(sm) {
        @include flex-column-center;

        .custom-app-btn {
          margin-bottom: toRem(15);
        }
      }
    }
  }
}
</style>

<template>
  <div class="about-page">
    <!-- ABOUT HERO BANNER  -->
    <about-hero-banner />

    <!-- FAQ SECTION  -->
    <faq-section :faqs="faqs" />

    <!-- STAT SECTION  -->
    <stat-section />

    <!-- CONTACT SECTION  -->
    <contact-section />
  </div>
</template>

<script>
import aboutHeroBanner from "@/modules/landing/components/about-comps/about-hero-banner";
import faqSection from "@/modules/landing/components/about-comps/faq-section";
import statSection from "@/modules/landing/components/about-comps/stat-section";
import contactSection from "@/modules/landing/components/about-comps/contact-section";
import { aboutFaqs } from "@/modules/landing/faqs";

export default {
  name: "aboutPage",

  metaInfo: {
    title: "About",
  },

  components: {
    aboutHeroBanner,
    faqSection,
    statSection,
    contactSection,
  },

  data() {
    return {
      faqs: [...aboutFaqs],
    };
  },
};
</script>

<style lang="scss" scoped>
.about-page {
}
</style>

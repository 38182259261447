<template>
  <section class="testimonial-section brand-white-bg">
    <div class="container px-3 px-sm-3 px-md-3 px-xl-2">
      <!-- TOP CONTENT -->
      <div class="top-content">
        <div class="left-content">
          <div class="title-text brand-dark-blue font-weight-700">
            Why students are choosing Class54
          </div>
          <div class="meta-text blackish-grey">
            See what some of them are saying.
          </div>
        </div>

        <div class="right-content">
          <div class="icon icon-quote"></div>
        </div>
      </div>

      <!-- BOTTOM CONTENT -->
      <div class="bottom-content">
        <div class="row">
          <div
            class="col-sm-12 col-md-6 col-lg-4"
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <testimonial-card :testimonial="testimonial" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import testimonialCard from "@/modules/landing/components/home-comps/testimonial-card";

export default {
  name: "testimonialSection",

  components: {
    testimonialCard,
  },

  data: () => ({
    testimonials: [
      {
        name: "Odulate Daniel",
        score: "285",
        exam: "2022 UTME",
        link: "https://youtube.com/shorts/U5TjP0muZU8?feature=share",
        comment:
          "I say thank you to God for the success of my UTME and also to Class54 App Developers. I admonish other students to use Class54 in preparation for their UTME. With God through Class54, success is guaranteed.",
      },
      {
        name: "Uzoma Iheadindu",
        score: "273",
        exam: "2022 UTME",
        link: "https://youtube.com/shorts/OokXK2YeWcw?feature=share",
        comment:
          "Class54 has helped me a lot since my last UTME exam, and when I was looking for a platform to practice for the exam, I felt lost and confused until I came across Class54 app.",
      },
      {
        name: "Shalom Olaide",
        score: "221",
        exam: "2022 UTME",
        link: "https://youtube.com/shorts/_XkWWa1ucCg?feature=share",
        comment:
          "Class54 helped me meet the required cut-off mark for my course in the 2022 UTME. Kudos to the developmental team and brains behind Class54",
      },
    ],
  }),
};
</script>

<style lang="scss">
.testimonial-section {
  padding: toRem(100) 0 toRem(150);

  @include breakpoint-down(lg) {
    padding: toRem(70) 0 toRem(110);
  }

  @include breakpoint-down(sm) {
    padding: toRem(50) 0 toRem(70);
  }

  .top-content {
    @include flex-row-between-nowrap;
    align-items: flex-start;
    margin-bottom: toRem(60);

    .left-content {
      width: 40%;

      @include breakpoint-down(xl) {
        width: 50%;
      }

      @include breakpoint-down(md) {
        width: 100%;
      }

      .title-text {
        @include font-height(48, 62);
        margin-bottom: toRem(24);

        @include breakpoint-down(xl) {
          @include font-height(38, 48);
        }

        @include breakpoint-down(lg) {
          @include font-height(34, 43);
        }

        @include breakpoint-down(md) {
          @include font-height(32, 44);
          margin-bottom: toRem(18);
          text-align: center;
        }

        @include breakpoint-down(sm) {
          @include font-height(30, 40);
        }

        @include breakpoint-down(xs) {
          @include font-height(29, 38);
        }
      }

      .meta-text {
        @include font-height(18, 27);

        @include breakpoint-down(lg) {
          @include font-height(16.5, 24);
        }

        @include breakpoint-down(md) {
          margin-bottom: toRem(10);
          text-align: center;
        }

        @include breakpoint-down(sm) {
          @include font-height(15, 22);
          margin-bottom: toRem(5);
        }

        @include breakpoint-down(xs) {
          @include font-height(14.75, 19);
        }
      }
    }

    .right-content {
      @include breakpoint-down(md) {
        display: none;
      }

      .icon {
        color: #f2f2f2;
        font-size: toRem(125);

        @include breakpoint-down(lg) {
          font-size: toRem(100);
        }
      }
    }
  }
}
</style>
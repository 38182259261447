<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-5 mx-auto">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div class="central-placement">
                <!-- ALERT ICON  -->
                <div class="avatar">
                  <img
                    v-lazy="staticAsset('Success-icon.svg', 'subscriptions')"
                    alt=""
                  />
                </div>

                <!-- TITLE TEXT  -->
                <div class="title-text text-center mgb-15">
                  Your activation code is here!
                </div>

                <!-- DESCRIPTION  -->
                <div class="description">
                  Please copy the activation code displayed below and paste in
                  your desktop software.
                </div>

                <!-- COPY ACTIVATION CODE  -->
                <div class="activation-code-entry rounded-4 w-100">
                  <div
                    class="token-display border-text font-weight-600 text-uppercase"
                  >
                    {{ activation_code }}
                  </div>

                  <div
                    class="copy-handler pointer font-weight-600 brand-green"
                    @click="copyActivationCode"
                  >
                    <div class="text smooth-animation" ref="copystate">
                      Copy
                    </div>
                    <div class="icon icon-copy"></div>

                    <input
                      type="text"
                      ref="activationCode"
                      :value="activation_code"
                      class="position-absolute index--9 ignore"
                      style="opacity: 0"
                    />
                  </div>
                </div>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formCard from "@/shared/components/form-card";

export default {
  name: "DesktopActivated",

  metaInfo: {
    title: "Desktop Activated!",
  },

  components: {
    formCard,
  },

  props: {
    activation_code: String,
  },

  data: () => ({
    page_data: {
      show_back_link: false,
      back_link_route: "",
      header: "",
      description: "",
      footer_text: "Thank you for choosing Class54.",
      footer_link: "",
    },
  }),

  methods: {
    copyActivationCode() {
      let code_input = this.$refs.activationCode;
      code_input.select();
      code_input.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");

      this.$refs.copystate.innerText = "Copied";

      setTimeout(() => (this.$refs.copystate.innerText = "Copy"), 4000);
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-code-entry {
  box-shadow: 0px 2px 4px rgba(0, 38, 19, 0.1);
  @include flex-row-between-nowrap;
  padding: toRem(19) toRem(16);
  position: relative;

  .token-display {
    @include font-height(15, 16);
  }

  .copy-handler {
    @include flex-row-end-nowrap;

    .text {
      @include font-height(15, 16);
      margin-right: toRem(6);
    }

    .icon {
      font-size: toRem(18);
    }
  }
}
</style>

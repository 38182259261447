<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-md-6 col-xl-5 px-xl-3 mx-auto">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div>
                <!-- SUBSCRIPTION PLAN  -->
                <div class="mgb-24">
                  <label class="label-text">Choose plan</label>
                  <single-data-select
                    placeholder="Select subscription plan"
                    :options="subscription_data"
                    :pre_select="pre_subscription"
                    :clear_selection="clear_selection"
                    @selectedOption="form.subscription_id = $event.toString()"
                  />
                </div>

                <!-- SUBMIT BUTTON -->
                <button
                  type="submit"
                  class="btn btn-block btn-primary mgt-16 w-100"
                  ref="continueBtn"
                  @click="selectMobileSubscription"
                  :disabled="isDisabled"
                >
                  Continue
                </button>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import formCard from "@/shared/components/form-card";
import singleDataSelect from "@/shared/components/single-data-select";

export default {
  name: "SelectSubscription",

  metaInfo: {
    title: "Select Subscription",
  },

  components: {
    formCard,
    singleDataSelect,
  },

  computed: {
    ...mapGetters({
      getAuthToken: "auth/getAuthToken",
      getAuthUser: "auth/getAuthUser",
    }),

    isDisabled() {
      return this.form.subscription_id ? false : true;
    },
  },

  watch: {
    $route: {
      handler(value) {
        if (value.query.verify && this.getAuthToken) this.checkSubscription();
      },
      immediate: true,
    },
  },

  data() {
    return {
      page_data: {
        show_back_link: true,
        back_link_route: "/#subscriptions",
        header: "Class54 subscription",
        description: "Unlimited practise questions and resources",
        footer_text: "",
        footer_link: "",
      },

      form: {
        subscription_id: "",
        phone: this.getAuthUser?.phone,
      },

      subscription_data: [
        {
          id: 14,
          amount: 100000,
          name: "Basic (NGN 1,500 - 1 month)",
          slug: "basic",
        },
        {
          id: 17,
          amount: 150000,
          name: "Premium (NGN 2,500 - 3 months)",
          slug: "premium",
        },
        {
          id: 19,
          amount: 300000,
          name: "Platinum (NGN 4,000 - 1 year)",
          slug: "platinum",
        },
      ],

      pre_subscription: null,
      clear_selection: false,
    };
  },

  mounted() {
    if (!this.$route.query.verify) this.updateSubscription();
  },

  methods: {
    ...mapActions({
      checkMobileSubscription: "storeSubscription/checkMobileSubscription",
      fetchAllSubscriptions: "storeSubscription/fetchAllSubscriptions",
    }),

    async fetchSubscriptions() {
      const subscriptions = await this.fetchAllSubscriptions();
      this.subscription_data = subscriptions.data;
    },

    updateSubscription() {
      let plan = this.$route.query.plan.toLowerCase();

      if (plan === "premium") {
        this.pre_subscription = this.subscription_data[1];
        this.form.subscription_id = this.subscription_data[1]?.id;
      } else if (plan === "platinums") {
        this.pre_subscription = this.subscription_data[2];
        this.form.subscription_id = this.subscription_data[2]?.id;
      } else {
        this.pre_subscription = this.subscription_data[0];
        this.form.subscription_id = this.subscription_data[0]?.id;
      }
    },

    getSelectedPlan() {
      let selection_index = this.subscription_data.findIndex(
        (item) => item.id === Number(this.form.subscription_id)
      );
      return this.subscription_data[selection_index];
    },

    selectMobileSubscription() {
      if (this.getAuthToken) this.checkSubscription();
      else
        this.$router.push({
          name: "Class54Login",
          query: { type: "subscription" },
        });
    },

    checkSubscription() {
      this.$bus.$emit("show-page-loader");

      this.checkMobileSubscription()
        .then((response) => {
          if (response?.status_code === 200) {
            if (response?.data.is_active) {
              this.$bus.$emit("show-page-loader");

              setTimeout(() => {
                this.$router.push({
                  name: "SubscriptionVerified",
                  params: {
                    plan_name: response.data.subscription_plan.name,
                    plan_span: response.data.subscription_plan.validity,
                    plan_validity: response.data.valid_until,
                  },
                });
              }, 2000);
            }
            // CALL PAYSTACK
            else {
              let selected_plan = this.getSelectedPlan();

              this.$router.push({
                name: "MakePayment",
                params: { plan: selected_plan.slug },
              });
            }
          }
          // CALL PAYSTACK
          else {
            let selected_plan = this.getSelectedPlan();

            this.$router.push({
              name: "MakePayment",
              params: { plan: selected_plan.slug },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/auth";
import general from "@/store/general";
import storeSubscription from "@/modules/subscriptions/storeSubscription";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    general,
    storeSubscription,
  },
});

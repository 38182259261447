<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-4 mx-auto">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div class="central-placement">
                <!-- ALERT ICON  -->
                <div class="avatar">
                  <img
                    v-lazy="staticAsset('Error-icon.svg', 'subscriptions')"
                    alt=""
                  />
                </div>

                <!-- TITLE TEXT  -->
                <div class="title-text">Something went wrong!</div>

                <!-- DESCRIPTION  -->
                <div class="description">
                  We’re sorry, we couldn’t automatically activate your app.
                  Check your card and try again.
                </div>

                <!-- SUBMIT BUTTON -->
                <router-link
                  to="/activate-desktop"
                  type="submit"
                  class="btn btn-block btn-primary mgt-16 w-100"
                >
                  Try again
                </router-link>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formCard from "@/shared/components/form-card";

export default {
  name: "DesktopFailed",

  metaInfo: {
    title: "Activation Failed!",
  },

  components: {
    formCard,
  },

  data: () => ({
    page_data: {
      show_back_link: false,
      back_link_route: "",
      header: "",
      description: "",
      footer_text: "",
      footer_link: "",
    },
  }),
};
</script>

<style lang="scss" scoped>
.central-placement {
  .avatar {
    border: toRem(1) solid rgba(#eb5757, 0.35) !important;
    background: rgba(#ffbaba, 0.3) !important;
  }
}
</style>

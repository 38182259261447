<template>
  <div class="processing-section w-100 smooth-transition smooth-animation">
    <div class="container h-100">
      <div class="row position-relative h-100">
        <div
          class="col-12 d-flex flex-column justify-content-center align-items-center position-absolute h-100"
        >
          <!-- LOADER -->
          <div class="loader mgb-20">
            <img :src="staticAsset('loader.svg')" class="animate" />
          </div>

          <!-- PROCESSING TEXT -->
          <p class="text brand-white text-center mgt-30">
            {{ loader_text || "Please wait" }} ...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionLoader",

  props: {
    loader_text: {
      type: String,
      default: "Please wait",
    },
  },
};
</script>

<style lang="scss" scoped>
// SPINNER ICON
@keyframes spin-icon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate {
  animation: spin-icon 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

.processing-section {
  z-index: 2009;
  @include fixed-display-area;
  background: rgba(#000, 0.8);

  .row {
    .col-12 {
      @include center-placement;
      .loader {
        position: inherit;
        @include square-shape(50);

        img {
          @include background-cover;
        }
      }
    }
  }

  .text {
    @include font-height(16, 29);
    position: relative;
    top: 55px;

    @include breakpoint-down(sm) {
      font-size: 14px;
    }

    @include breakpoint-down(xs) {
      font-size: 12.75px;
    }
  }
}
</style>

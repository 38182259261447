<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3C7.2 3 3 6.86 3 12C3 14.12 3.74 16.07 4.97 17.61L3 19.59L4.41 21L6.38 19.03C7.9755 20.3054 9.95739 21.0001 12 21C14.3 21 16.61 20.12 18.36 18.36C19.1982 17.5267 19.863 16.5357 20.3162 15.4441C20.7693 14.3525 21.0017 13.1819 21 12V3H12ZM15.83 12.26L10.67 16.89C10.51 17.04 10.26 17.03 10.11 16.88C10.0422 16.8124 10.0009 16.7228 9.9936 16.6274C9.98626 16.532 10.0134 16.4371 10.07 16.36L12.51 13.03L8.46 12.63C8.36105 12.6207 8.26691 12.5829 8.18899 12.5213C8.11107 12.4596 8.0527 12.3766 8.02096 12.2824C7.98921 12.1883 7.98545 12.0869 8.01013 11.9906C8.0348 11.8943 8.08686 11.8073 8.16 11.74L13.32 7.11C13.48 6.96 13.73 6.97 13.88 7.12C14.02 7.26 14.04 7.48 13.92 7.64L11.48 10.97L15.53 11.37C15.98 11.41 16.16 11.96 15.83 12.26Z"
      fill="#29CC7A"
    />
  </svg>
</template>

<script>
export default {
  name: "PricingPlatinumPlan",
};
</script>

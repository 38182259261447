<template>
  <div class="not-found-error">
    <!-- ERROR TITLE -->
    <div class="error-title font-weight-800 brand-black text-center mgb-20">
      500
    </div>

    <!-- INFO TEXT -->
    <div class="info-text text-center light-dark-text mgb-6">
      Oops! There was an error, please try again later
    </div>

    <div class="info-sub-text mgb-30 text-center grey-text">
      Server encountered an internal error and was unable to complete your
      request.
    </div>
  </div>
</template>

<script>
export default {
  name: "500",
};
</script>

<style>
</style>
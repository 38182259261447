const subscriptionRoutes = [
  {
    path: "/process-payment/:token",
    name: "ProcessPayment",
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/process-payment"
      ),
  },

  {
    path: "/select-subscription",
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ "@/layout/base-landing-layout"
      ),

    children: [
      {
        path: "",
        name: "SelectSubscription",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/select-subscription"
          ),
      },

      {
        path: "/make-payment/:plan",
        name: "MakePayment",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/make-payment"
          ),
        meta: {
          // requiresAuth: true,
        },
      },

      {
        path: "/jamb-software-download",
        name: "DesktopSubscription",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/desktop-subscription"
          ),
      },

      {
        path: "/activate-desktop",
        name: "ActivateDesktop",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/activate-desktop"
          ),
      },

      {
        path: "/desktop-activated",
        name: "DesktopActivated",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/desktop-activated"
          ),
        props: true,
      },

      {
        path: "/desktop-error",
        name: "DesktopError",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/desktop-failed"
          ),
      },

      {
        path: "/subscription-success",
        name: "SubscriptionSuccess",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/subscription-success"
          ),
      },

      {
        path: "/subscription-verified",
        name: "SubscriptionVerified",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/subscription-verified"
          ),
        props: true,
      },

      {
        path: "/subscription-failed",
        name: "SubscriptionError",
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ "@/modules/subscriptions/pages/subscription-error"
          ),
      },
    ],
  },
];

export default subscriptionRoutes;

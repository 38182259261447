<template>
  <div class="cta-block-two">
    <div class="container px-3 px-sm-3 px-md-3 px-xl-2">
      <div class="block-content mx-auto">
        <!-- left SECTION  -->
        <div class="left-section pdt-10">
          <!-- TITLE TEXT  -->
          <div
            class="title-text font-weight-700 brand-dark-blue"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            Experience real examination condition with our desktop software
          </div>

          <!-- META TEXT  -->
          <div
            class="meta-text brand-dark-blue"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="90"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            Download and install our windows software on your personal computer
            and experience a simulation of the real examination conditions
            without using the internet.
          </div>

          <!-- DOWNLOAD CTA ROW  -->
          <div
            class="btn-row"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="120"
            data-aos-duration="1000"
            data-aos-easing="ease-in"
          >
            <!-- WINDOWS DOWNLOAD  -->
            <router-link
              to="/jamb-software-download"
              class="custom-app-btn mgr-15 rounded-50"
            >
              <div class="icon icon-microsoft"></div>

              <div>
                <div class="text-one">Download the</div>
                <div class="text-two">Windows Software</div>
              </div>
            </router-link>

            <!-- ACTIVATE DESKTOP  -->
            <router-link
              to="/activate-desktop"
              class="btn btn-outline brand-dark-blue font-weight-700"
            >
              Activate desktop online
            </router-link>
          </div>
        </div>

        <!-- RIGHT SECTION  -->
        <div class="right-section">
          <img
            v-lazy="staticAsset('DesktopScreen.png', 'landing')"
            alt="class54Desktop"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ctaBlockTwo",
};
</script>

<style lang="scss" scoped>
.cta-block-two {
  padding: toRem(80) 0 toRem(80);
  // background: #e8f4f8;

  .block-content {
    @include flex-row-center-nowrap;
    align-items: flex-start;

    @include breakpoint-down(xl) {
      @include flex-row-between-nowrap;
    }

    @include breakpoint-down(md) {
      @include flex-row-between-wrap;
    }

    .left-section {
      width: 50%;
      padding-right: toRem(10);

      @include breakpoint-down(md) {
        width: 100%;
        margin-bottom: toRem(45);
      }

      .title-text {
        @include font-height(46, 60);
        margin-bottom: toRem(20);
        text-align: left;
        width: 95%;

        @include breakpoint-down(xl) {
          @include font-height(38, 45);
        }

        @include breakpoint-custom-down(850) {
          @include font-height(32, 40);
        }

        @include breakpoint-down(sm) {
          width: 100%;
        }

        @include breakpoint-down(xs) {
          @include font-height(28, 36);
          text-align: center;
        }
      }

      .meta-text {
        @include font-height(16, 28);
        margin-bottom: toRem(36);
        width: 95%;

        @include breakpoint-down(xl) {
          @include font-height(15, 24);
          width: 94%;
        }

        @include breakpoint-down(sm) {
          width: 100%;
        }

        @include breakpoint-down(xs) {
          text-align: center;
        }
      }

      .btn-row {
        @include flex-row-start-nowrap;

        @include breakpoint-down(xs) {
          @include flex-row-center-wrap;
          width: 100%;
        }

        @include breakpoint-custom-down(420) {
          @include flex-column-center;
        }

        .custom-app-btn {
          @include breakpoint-custom-down(420) {
            margin-right: 0 !important;
            margin-bottom: toRem(15);

            .icon {
              font-size: toRem(24);
              margin-right: toRem(10);
            }

            .text-two {
              @include font-height(13.5, 17);
            }
          }
        }

        .btn {
          padding-left: toRem(16);
          padding-right: toRem(16);

          @include breakpoint-custom-down(420) {
            @include font-height(13.25, 17);
            padding-left: toRem(21.5);
            padding-right: toRem(21.5);
          }
        }
      }
    }

    .right-section {
      width: 50%;

      @include breakpoint-down(md) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>

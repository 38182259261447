<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-4 mx-auto">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div class="central-placement">
                <!-- ALERT ICON  -->
                <img
                  v-lazy="staticAsset('Verified.png', 'subscriptions')"
                  alt=""
                />

                <!-- TITLE TEXT  -->
                <div class="title-text text-center mgt-25 mgb-17">
                  You currently have an active subscription.
                </div>

                <!-- DESCRIPTION  -->
                <div class="description text-center">
                  Your current {{ plan_name }} ({{ plan_span }}) Subscription
                  <br />

                  <span class="font-weight-600"
                    >expires on {{ getFormattedDate }}.</span
                  >
                </div>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formCard from "@/shared/components/form-card";

export default {
  name: "SubscriptionVerified",

  metaInfo: {
    title: "Subscription Verified!",
  },

  components: {
    formCard,
  },

  computed: {
    getFormattedDate() {
      let { d3, m3, y1 } = this.$date.formatDate(this.plan_validity).getAll();

      return `${d3} ${m3}, ${y1}`;
    },
  },

  created() {
    let subscription_info =
      JSON.parse(localStorage.getItem("subscriptionInfo")) || false;
    this.plan_name = subscription_info?.plan_name ?? this.plan_name;
    this.plan_span = subscription_info?.plan_span ?? this.plan_span;
    this.plan_validity = subscription_info?.plan_validity ?? this.plan_validity;
  },

  data: () => ({
    page_data: {
      show_back_link: false,
      back_link_route: "",
      header: "",
      description: "",
      footer_text: "Go Home",
      footer_link: "/",

      plan_name: "Basic",
      plan_span: "1 Month",
      plan_validity: "2022-01-30 21:15:29",
    },
  }),

  beforeDestroy() {
    // CLEAR OUT SAVED PAYMENT VERIFICATION DATA
    localStorage.remove("subscriptionInfo");
  },
};
</script>

<style lang="scss" scoped>
.central-placement {
  img {
    width: toRem(260);
    height: auto;
  }
}
.title-text {
  width: 85%;
  line-height: 32px !important;
}
.description {
  width: 88%;
}
</style>

import { $serviceApi as api } from "@/services/apiHelper";

const routes = {
  activate_desktop: "admin/desktop/activate",
  check_desktop_status: "admin/desktop/subscription-status",
  update_downloads: "admin/desktop/download-counter",

  check_mobile_sub: "/api/subscription-status",
  activate_mobile_sub: "api/subscription",
  verify_mobile_sub: "api/subscription/payment/verification",

  fetch_subscriptions: "/admin/subscription-plans",
};

export default {
  // ACTIVATE DESKTOP
  async activateDesktop(_, payload) {
    return api.adminService().push(routes.activate_desktop, payload);
  },

  // CHECK DESKTOP SUBSCRIPTION STATUS
  async checkDesktopSubscription(_, payload) {
    return api.adminService().push(routes.check_desktop_status, payload);
  },

  // INCREASE DOWNLOAD COUNT
  async updateDownloadCount(_, payload) {
    return api.adminService().push(routes.update_downloads, payload);
  },

  async checkMobileSubscription() {
    return api.userService().fetch(routes.check_mobile_sub);
  },

  async activateMobileSubscription(_, payload) {
    return api.userService().push(routes.activate_mobile_sub, payload);
  },

  async verifyMobileSubscription(_, transaction_ref) {
    return api
      .userService()
      .push(routes.verify_mobile_sub, { transaction_ref });
  },

  async fetchAllSubscriptions() {
    return api.adminService().fetch(routes.fetch_subscriptions);
  },
};

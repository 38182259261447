const errorRoutes = [
  {
    path: "/500",
    component: () =>
      import(/* webpackChunkName: "errorRoute" */ "@/layout/error-layout"),
    children: [
      {
        path: "",
        name: "ServerError",
        component: () =>
          import(
            /* webpackChunkName: "errorRoute" */ "@/modules/error/pages/500"
          ),
        meta: {
          guest: true,
        },
      },

      {
        path: "/*",
        name: "NotFoundError",
        component: () =>
          import(
            /* webpackChunkName: "errorRoute" */ "@/modules/error/pages/404"
          ),
        meta: {
          guest: true,
        },
      },
    ],
  },
];

export default errorRoutes;

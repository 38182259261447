<template>
  <div class="desktop-cta">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="desktop-content mx-auto">
        <!-- SECTION TITLE  -->
        <div
          class="intro-text brand-dark-blue text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Have you downloaded and installed Class54 software?
        </div>

        <!-- TITLE TEXT  -->
        <div
          class="title-text brand-dark-blue font-weight-700 text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="90"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Activate your software to access thousands more questions
        </div>

        <!-- DOWNLOAD BUTTON  -->
        <router-link to="/activate-desktop" class="btn btn-primary"
          >Activate Now
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerCTA",

  data: () => ({
    show_dropdown: false,
  }),
};
</script>

<style lang="scss" scoped>
.desktop-cta {
  background: #fff6db;

  .desktop-content {
    padding: toRem(64) 0;
    @include flex-column-center;

    @include breakpoint-down(sm) {
      padding: toRem(56) 0;
    }

    .intro-text {
      @include font-height(17, 26);
      margin-bottom: toRem(24);

      @include breakpoint-custom-down(850) {
        @include font-height(15.5, 22);
      }

      @include breakpoint-down(sm) {
        @include font-height(15, 20);
      }

      @include breakpoint-down(xs) {
        @include font-height(14.5, 18);
      }
    }

    .title-text {
      @include font-height(43, 54);
      margin-bottom: toRem(31);
      width: 64%;

      @include breakpoint-down(xl) {
        @include font-height(40, 50);
      }

      @include breakpoint-custom-down(850) {
        @include font-height(32, 40);
      }

      @include breakpoint-down(md) {
        width: 80%;
      }

      @include breakpoint-down(sm) {
        @include font-height(28, 38);
        width: 95%;
      }

      @include breakpoint-down(xs) {
        @include font-height(26, 34);
      }
    }
  }
}
</style>

<template>
  <ModalCover
    @closeModal="$emit('closeTriggered')"
    modal_style="modal-sm-md"
    :show_close="true"
  >
    <!-- MODAL BODY -->
    <template slot="modal-cover-body">
      <div class="download-area w-100">
        <!-- ICON TOP -->
        <div class="icon-top mgb-12">
          <img
            v-lazy="staticAsset('DownloadIcon.png', 'landing')"
            alt="downloadGradelyDesktop"
            class="w-100 h-100"
          />
        </div>

        <div
          class="title-text font-weight-700 brand-dark-blue mgb-10 text-center"
        >
          Enter your phone number
        </div>

        <div class="meta-text dark-text mgb-18 text-center">
          Your phone number is needed in order to complete this download.
        </div>

        <!-- PHONE NUMBER -->
        <div class="email-block mgb-10 w-100">
          <div class="input-container">
            <!-- DROPDOWN SELECTOR -->
            <div
              class="select-wrapper dropdown-selector"
              @click="toggleView"
              v-on-clickaway="forceClose"
            >
              <!-- SELECTOR VALUE -->
              <div class="dropdown-selector-value">
                <!-- LOGO -->
                <div class="avatar logo">
                  <img
                    v-lazy="
                      selected_country.logo.startsWith('https')
                        ? selected_country.logo
                        : dynamicAsset('Nigeria.svg', 'subscriptions')
                    "
                    alt=""
                    class="avatar-img"
                  />
                </div>

                <!-- VALUE -->
                <div class="value">
                  {{ selected_country.dialing_code }}
                </div>
              </div>

              <!-- SELECTOR ICON -->
              <div
                class="
                  dropdown-icon
                  icon-caret-down
                  brand-black
                  smooth-transition
                "
                :class="{ 'rotate-180': option_select }"
              ></div>

              <!-- OPTION WRAPPER -->
              <div
                class="select-option-wrapper rounded-4 mgt-5 smooth-animation"
                v-if="option_select"
              >
                <!-- OPTIONS -->
                <div class="option-scroll-wrapper">
                  <div
                    class="
                      option-row option-mix-row
                      text-capitalize
                      position-relative
                    "
                    style="z-index: 9999"
                    v-for="(country, index) in country_data"
                    @click="updateCountryState(index)"
                    :key="index"
                  >
                    <div class="left">
                      {{ country.name }} ({{ country.dialing_code }})
                    </div>

                    <div class="right">
                      <div class="avatar">
                        <img v-lazy="country.logo" alt="" class="avatar-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <input
              type="number"
              class="form-control"
              v-model="phone"
              placeholder="e.g 090656873xx"
            />
          </div>
        </div>

        <!-- SUBMIT BUTTON -->
        <button
          @click="incrementCount"
          class="btn btn-block btn-primary mgt-16 w-100"
          :disabled="isDisabled"
        >
          Complete Download
        </button>
      </div>
    </template>
  </ModalCover>
</template>

<script>
import { DESKTOP_DOWNLOAD_BASE } from "@/env";
import { mapActions } from "vuex";
import ModalCover from "@/shared/components/modal-cover";
// import authMixinHelper from "@/shared/mixins/authMixinHelper";

export default {
  name: "desktopDownloadModal",

  //   mixins: [authMixinHelper],

  components: {
    ModalCover,
  },

  computed: {
    isDisabled() {
      return [10, 11, 12, 13, 14].includes(this?.phone?.length) ? false : true;
    },
  },

  data() {
    return {
      phone: "",
      country_id: 1,

      country_data: [],
      selected_country: {
        id: 1,
        logo: "Nigeria",
        name: "Nigeria",
        dialing_code: "+234",
      },

      option_select: false,
    };
  },

  mounted() {
    this.getAllCountries();
  },

  methods: {
    ...mapActions({
      getCountries: "general/getCountries",
      updateDownloadCount: "storeSubscription/updateDownloadCount",
    }),

    toggleView() {
      this.option_select = !this.option_select;
    },

    forceClose() {
      this.option_select = false;
    },

    formatPhone(phone, dialing_code) {
      if (phone.charAt(0) == 0)
        return `${dialing_code.substring(1)}${phone.substring(1)}`;
      else if (phone.charAt(0) === "+") return phone.substring(1);
      else if (phone.length === 10)
        return `${dialing_code.substring(1)}${phone}`;
      else return phone;
    },

    fetchDownloadData() {
      let payload = {
        exam_id: "1",
        year_id: "56",
        increment: "true",
        phone_number: this.formatPhone(
          this.phone,
          this.selected_country.dialing_code
        ),
      };

      this.updateDownloadCount(payload)
        .then((response) => {
          //   console.log(response);
        })
        .catch((err) => console.log(err));
    },

    incrementCount() {
      this.fetchDownloadData();
      this.$emit("increaseCount");

      let link = document.createElement("a");
      link.setAttribute("href", `${DESKTOP_DOWNLOAD_BASE}JAMB-2021/utme.exe`);
      link.setAttribute("download", "Class54 Desktop App");
      link.click();

      setTimeout(() => this.$emit("closeTriggered"), 1000);
    },

    getAllCountries() {
      this.getCountries()
        .then((response) => {
          //   console.log(response);
          if (response.status_code === 200) {
            this.country_data = response.data;
            // this.prepopulateCountry(response.data);
          } else this.country_data = [];
        })
        .catch(() => (this.country_data = []));
    },

    prepopulateCountry(countries) {
      let current_country = countries.filter(
        (country) => country.name == "Nigeria"
      );

      this.selected_country.id = current_country[0].id;
      this.selected_country.name = current_country[0].name;
      this.selected_country.logo = current_country[0].logo;
      this.selected_country.dialing_code = current_country[0].dialing_code;

      this.form.country_id = current_country[0].id.toString();
    },

    updateCountryState(index) {
      this.selected_country.id = this.country_data[index].id;
      this.selected_country.name = this.country_data[index].name;
      this.selected_country.logo = this.country_data[index].logo;
      this.selected_country.dialing_code =
        this.country_data[index].dialing_code;

      this.country_id = this.country_data[index].id.toString();
    },

    // FORMAT PHONE TO 11 CHARACTERS
    formatPhone(phone, dialing_code) {
      if (phone.charAt(0) == 0)
        return `${dialing_code.substring(1)}${phone.substring(1)}`;
      else if (phone.charAt(0) === "+") return phone.substring(1);
      else if (phone.length === 10)
        return `${dialing_code.substring(1)}${phone}`;
      else return phone;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-area {
  position: relative;
  background: $brand-white;
  padding: toRem(60) toRem(125);
  @include flex-column-center;
  min-height: 52vh;
  height: auto;
  max-height: 70vh;

  @include breakpoint-down(md) {
    padding: toRem(60) toRem(90);
  }

  @include breakpoint-down(sm) {
    padding: toRem(50) toRem(52);
  }

  @include breakpoint-down(xs) {
    padding: toRem(50) toRem(28);
  }

  .icon-top {
    @include square-shape(50);
  }

  .title-text {
    @include font-height(19, 22);

    @include breakpoint-down(sm) {
      @include font-height(17, 18);
    }
  }

  .meta-text {
    @include font-height(15.5, 24);

    @include breakpoint-down(sm) {
      @include font-height(14.5, 24);
    }
  }
}
</style>

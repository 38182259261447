const sharedRoutes = [
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/layout/base-landing-layout"),

    children: [
      {
        path: "",
        name: "Class54Login",
        component: () =>
          import(/* webpackChunkName: "auth" */ "@/shared/pages/login"),
        meta: {
          guest: true,
        },
      },

      {
        path: "/create-account",
        name: "Class54CreateAccount",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "@/shared/pages/create-account"
          ),
        meta: {
          guest: true,
        },
      },

      {
        path: "/forgot-password",
        name: "Class54ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "@/shared/pages/forgot-password"
          ),
        meta: {
          guest: true,
        },
      },

      {
        path: "/verify-phone/:phone",
        name: "Class54VerifyPhone",
        component: () =>
          import(/* webpackChunkName: "auth" */ "@/shared/pages/verify-phone"),
        meta: {
          guest: true,
        },
      },

      {
        path: "/create-password/:phone",
        name: "Class54CreatePassword",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "@/shared/pages/create-password"
          ),
        meta: {
          guest: true,
        },
      },
    ],
  },
];

export default sharedRoutes;

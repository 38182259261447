<template>
  <div class="price-data-section">
    <div class="container px-3 px-sm-3 px-md-3 px-xl-2">
      <!-- PRICING TOP -->
      <div class="pricing-top">
        <pricingCard
          v-for="(price, index) in price_list"
          :key="index"
          :pricing_data="price"
          :index="index"
        />
      </div>

      <!-- PRICING BOTTOM -->
      <div class="pricing-bottom">
        <div class="title-text">Each one of the subscription plans unlocks</div>

        <!-- OFFER LIST -->
        <div class="offer-list">
          <div
            class="offer"
            v-for="(offer, index) in pricing_offers"
            :key="index"
          >
            <div class="icon">
              <pricing-check />
            </div>

            <div class="text">{{ offer }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pricingCheck from "@/modules/landing/components/pricing-comps/pricing-check";
import pricingCard from "@/modules/landing/components/pricing-comps/pricing-card";

export default {
  name: "PricingData",

  components: {
    pricingCheck,
    pricingCard,
  },

  data() {
    return {
      price_list: [
        {
          plan_type: "Basic",
          plan_description:
            "Full access to WAEC, JAMB, and POST UTME exams with additional benefits for a one-month period.",
          plan_amount: "₦1,500",
          plan_duration: "1 month",
          plan_action: "",
        },
        {
          plan_type: "Premium",
          plan_description:
            "Full access to WAEC, JAMB, and POST UTME exams with additional benefits for a three-month period.",
          plan_amount: "₦2,500",
          plan_duration: "3 months",
          plan_action: "",
        },
        {
          plan_type: "Platinum",
          plan_description:
            "Full access to WAEC, JAMB, and POST UTME exams with additional benefits for a one-year period.",
          plan_amount: "₦4,000",
          plan_duration: "1 year",
          plan_action: "",
        },
      ],

      pricing_offers: [
        "Over 24 years of JAMB past questions and standardised questions with detailed explanations.",
        "POST UTME past questions for over 36 institutions in Nigeria.",
        "Over 24 years of WAEC theory and objective past questions with detailed explanations",
        "Full access to detailed Study Notes covering all subjects according to JAMB Syllabus",
        "Detailed Performance Analysis to know how exactly how prepared you are",
        "Fast and highly responsive customer support",
        "Practise by topic shuffled across years ",
        "Smart voice assistant",
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.price-data-section {
  position: relative;
  margin: toRem(-110) auto toRem(20);

  @include breakpoint-down(sm) {
    margin: toRem(-90) auto toRem(20);
  }

  @include breakpoint-down(sm) {
    margin: toRem(-100) auto toRem(20);
  }

  .pricing-top {
    @include flex-row-between-nowrap;
    margin-bottom: toRem(20);

    @include breakpoint-down(md) {
      @include flex-column-start-start;
      gap: toRem(24);
    }
  }

  .pricing-bottom {
    border: toRem(1) solid #e0e0e0;
    border-radius: toRem(24);
    padding: toRem(24);

    .title-text {
      @include font-height(18, 24);
      margin-bottom: toRem(24);
      font-weight: 700;
      color: #464646;
    }

    .offer-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: toRem(16);
      row-gap: toRem(24);

      @include breakpoint-down(lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
      }

      .offer {
        @include flex-row-start-nowrap;
        align-items: flex-start;
        gap: toRem(10);

        .icon {
        }

        .text {
          @include font-height(14, 22);
          color: #494848;
        }
      }
    }
  }
}
</style>

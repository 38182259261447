<template>
  <section class="exam-list-section brand-white-bg">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="exam-content mx-auto">
        <!-- TITLE TEXT  -->
        <div
          class="title-text font-weight-700 brand-dark-blue text-center mgb-4"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Prepare for major examinations
        </div>

        <!-- META TEXT  -->
        <div
          class="meta-text brand-dark-blue text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="80"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Top-quality personalised exam preparation available
        </div>

        <!-- EXAM ROW  -->
        <div
          class="exam-row smooth-transition"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="120"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <exam-card
            v-for="(exam, index) in shrinked_list"
            :key="index"
            :title="exam.title"
          />
        </div>

        <!-- SHOW MORE BUTTON  -->
        <button class="btn btn-outline mgt-20" @click="toggleShowMore">
          <div class="text text-capitalize">Show {{ info_text }}</div>
          <div
            class="icon icon-caret-down"
            :class="info_text === 'less' ? 'rotate-180' : null"
          ></div>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import examCard from "@/modules/landing/components/home-comps/exam-card";

export default {
  name: "examListSection",

  components: {
    examCard,
  },

  data: () => ({
    exam_list: [
      {
        id: 1,
        title: "JAMB (UTME)",
      },
      {
        id: 2,
        title: "WAEC",
      },
      {
        id: 3,
        title: "JUNIOR WAEC (BECE)",
      },
      {
        id: 4,
        title: "COMMON ENTRANCE",
      },
      {
        id: 5,
        title: "JOB APTITUDE TESTS",
      },
      {
        id: 6,
        title: "NECO",
      },
      {
        id: 7,
        title: "POST-UTME",
      },
      {
        id: 8,
        title: "SCHOOL OF NURSING",
      },
    ],

    show_more: false,
    info_text: "more",
    shrink_length: 6,
    shrinked_list: [],
  }),

  mounted() {
    this.processExamList();
  },

  methods: {
    processExamList() {
      this.shrinked_list =
        this.exam_list.length > this.shrink_length
          ? this.exam_list.slice(0, 6)
          : this.exam_list;

      this.show_more =
        this.exam_list.length > this.shrink_length ? true : false;
    },

    toggleShowMore() {
      if (this.shrinked_list.length <= 6) {
        this.shrinked_list = this.exam_list;
        this.info_text = "less";
      } else {
        this.shrinked_list = this.exam_list.slice(0, 6);
        this.info_text = "more";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exam-list-section {
  padding: toRem(64) 0;

  @include breakpoint-down(xs) {
    padding: toRem(40) 0;
  }

  .exam-content {
    @include flex-column-center;

    .title-text {
      @include font-height(42, 54);

      @include breakpoint-down(xl) {
        @include font-height(40, 52);
      }

      @include breakpoint-custom-down(850) {
        @include font-height(34, 45);
      }

      @include breakpoint-down(xs) {
        @include font-height(28, 36);
      }
    }

    .meta-text {
      @include font-height(17, 26);
      margin-bottom: toRem(68);

      @include breakpoint-down(xl) {
        @include font-height(16, 24);
      }

      @include breakpoint-custom-down(850) {
        @include font-height(15, 22);
        margin-bottom: toRem(60);
      }

      @include breakpoint-down(sm) {
        margin-bottom: toRem(45);
      }

      @include breakpoint-down(xs) {
        @include font-height(14, 20);
        margin-bottom: toRem(40);
      }
    }

    .exam-row {
      @include flex-row-center-wrap;
    }

    .btn-outline {
      @include flex-row-center-nowrap;
      padding: toRem(14) toRem(52);

      .text {
        font-size: toRem(13);
      }

      .icon {
        margin-left: toRem(7);
        font-size: toRem(18);
      }
    }
  }
}
</style>

<template>
  <div class="not-found-error">
    <!-- ERROR TITLE -->
    <div class="error-title font-weight-800 brand-black text-center mgb-20">
      404
    </div>

    <!-- INFO TEXT -->
    <div class="info-text text-center light-dark-text mgb-30">
      Oops! The page you’re looking for doesn’t exist
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style lang="scss" scoped></style>

import axios from "axios";
import { urlHash } from "@/services/routeHelper";
import { ADMIN_API_URL, CUSTOMER_API_URL } from "@/env";

// SERVICE API CLSS
class serviceApi {
  // GENERATE ADMIN BASE URL
  adminService() {
    axios.defaults.baseURL = ADMIN_API_URL;
    return this;
  }

  // GENERATE CUSTOMER BASE URL
  userService() {
    axios.defaults.baseURL = CUSTOMER_API_URL;
    return this;
  }

  // GET API REQUEST
  async fetch(url) {
    try {
      let response = await axios.get(urlHash(url), this.setupHeaders());
      return response.data;
    } catch (err) {
      this.handleErrors(err);
    }
  }

  // POST API REQUEST
  async push(url, payload = null, is_attach = false) {
    try {
      let response = await axios.post(
        url,
        payload,
        this.setupHeaders(is_attach)
      );
      return response.data;
    } catch (err) {
      this.handleErrors(err);
    }
  }

  // UPDATE API REQUEST
  async update(url, payload = {}, is_attach = false) {
    try {
      let response = await axios.put(
        url,
        payload,
        this.setupHeaders(is_attach)
      );
      return response.data;
    } catch (err) {
      this.handleErrors(err);
    }
  }

  // DELETE API REQUEST
  async remove(url, payload = {}) {
    try {
      let response = await axios.delete(url, {
        data: payload,
        ...this.setupHeaders(),
      });
      return response.data;
    } catch (err) {
      this.handleErrors(err);
    }
  }

  // HABDLE API REQUEST ERRORS
  async handleErrors(err) {
    console.log(err);
    return false;
  }

  // SETUP REQUEST
  setupHeaders(attach = false) {
    return attach
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("falseEntryToken")}`,
          },
        }
      : {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("falseEntryToken")}`,
          },
        };
  }

  // RETURN DATA SAVED IN STORE
  deliverFromStore(data = [], pagination = {}) {
    return {
      code: 200,
      data,
      pagination,
    };
  }
}

export const $serviceApi = new serviceApi();

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 14.0006V22.0006H7C6.20435 22.0006 5.44129 21.6845 4.87868 21.1219C4.31607 20.5593 4 19.7962 4 19.0006V15.0006C4 14.7354 4.10536 14.481 4.29289 14.2935C4.48043 14.1059 4.73478 14.0006 5 14.0006H11ZM19 14.0006C19.2652 14.0006 19.5196 14.1059 19.7071 14.2935C19.8946 14.481 20 14.7354 20 15.0006V19.0006C20 19.7962 19.6839 20.5593 19.1213 21.1219C18.5587 21.6845 17.7956 22.0006 17 22.0006H13V14.0006H19ZM16.5 2.00059C17.0905 2.00047 17.6715 2.14975 18.1888 2.43455C18.706 2.71936 19.1429 3.13041 19.4586 3.62945C19.7743 4.1285 19.9586 4.6993 19.9943 5.28873C20.03 5.87816 19.9161 6.46705 19.663 7.00059H20C20.5304 7.00059 21.0391 7.2113 21.4142 7.58637C21.7893 7.96145 22 8.47015 22 9.00059V10.0006C22 10.531 21.7893 11.0397 21.4142 11.4148C21.0391 11.7899 20.5304 12.0006 20 12.0006H13V7.00059H11V12.0006H4C3.46957 12.0006 2.96086 11.7899 2.58579 11.4148C2.21071 11.0397 2 10.531 2 10.0006V9.00059C2 8.47015 2.21071 7.96145 2.58579 7.58637C2.96086 7.2113 3.46957 7.00059 4 7.00059H4.337C4.11488 6.53174 3.99977 6.01938 4 5.50059C4 3.56759 5.567 2.00059 7.483 2.00059C9.238 1.97059 10.795 3.09259 11.864 4.93459L12 5.17759C13.033 3.26359 14.56 2.06359 16.291 2.00259L16.5 2.00059ZM7.5 4.00059C7.10218 4.00059 6.72064 4.15862 6.43934 4.43993C6.15804 4.72123 6 5.10276 6 5.50059C6 5.89841 6.15804 6.27994 6.43934 6.56125C6.72064 6.84255 7.10218 7.00059 7.5 7.00059H10.643C9.902 5.09559 8.694 3.98059 7.5 4.00059ZM16.483 4.00059C15.303 3.98059 14.098 5.09659 13.357 7.00059H16.5C16.8978 6.99833 17.2785 6.83814 17.5582 6.55524C17.8379 6.27234 17.9938 5.88991 17.9915 5.49209C17.9892 5.09426 17.829 4.71363 17.5462 4.43392C17.2633 4.15421 16.8808 3.99833 16.483 4.00059Z"
      fill="#29CC7A"
    />
  </svg>
</template>

<script>
export default {
  name: "PricingPremiumPlan",
};
</script>


<template>
  <div>Please wait...</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProcessPayment",

  created() {
    this.authenticateUser();
  },

  methods: {
    ...mapActions({ decodeToken: "auth/decodeToken" }),

    authenticateUser() {
      this.decodeToken(this.$route.params.token)
        .then((response) => {
          if (response?.status_code === 200) {
            this.$router.push({
              name: "MakePayment",
              params: { plan: this.$route.query.plan },
            });
          } else location.href = "/select-subscription?plan=basic";
        })
        .catch(() => (location.href = "/select-subscription?plan=basic"));
    },
  },
};
</script>

<style></style>

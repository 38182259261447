<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-3 mx-auto">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div class="central-placement">
                <!-- ALERT ICON  -->
                <div class="avatar">
                  <img
                    v-lazy="staticAsset('Success-icon.svg', 'subscriptions')"
                    alt=""
                  />
                </div>

                <!-- TITLE TEXT  -->
                <div class="title-text">Subscription successful</div>

                <!-- DESCRIPTION  -->
                <div class="description">
                  If you do not have the Class54 mobile app, please download
                  below. Once installed, log in with your details and your
                  subscription will be active.
                </div>

                <!-- ANDROID DOWNLOAD  -->
                <div class="custom-app-btn rounded-50 mgb-20">
                  <div class="icon icon-google-play"></div>

                  <div>
                    <div class="text-one">Download on</div>
                    <div class="text-two">Google Play</div>
                  </div>
                </div>

                <div class="custom-app-btn rounded-50">
                  <div class="icon icon-apple"></div>

                  <div>
                    <div class="text-one">Download on</div>
                    <div class="text-two">App Store</div>
                  </div>
                </div>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formCard from "@/shared/components/form-card";

export default {
  name: "SubscriptionSuccess",

  metaInfo: {
    title: "Subscription Successful!",
  },

  components: {
    formCard,
  },

  watch: {
    $route: {
      handler(value) {
        if (value.query.ref) this.verifySubscription();
      },
      immediate: true,
    },
  },

  data: () => ({
    page_data: {
      show_back_link: false,
      back_link_route: "",
      header: "",
      description: "",
      footer_text: "Go Home",
      footer_link: "/",
    },
  }),

  methods: {
    ...mapActions({
      verifyMobileSubscription: "storeSubscription/verifyMobileSubscription",
    }),

    verifySubscription() {
      this.verifyMobileSubscription(this.$route.query.ref);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="subscription-card rounded-8 white-text h-auto">
    <!-- SUBSCRIPTION TITLE  -->
    <div
      class="title-text font-weight-700 mgb-4 brand-dark-blue text-capitalize"
    >
      {{ subscription.name }}
    </div>

    <!-- AMOUNT  -->
    <div class="amount-row">
      <div class="amount brand-dark-blue font-weight-700">
        NGN {{ subscription.amount }}
      </div>
      <div class="duration light-grey-text">
        {{ subscription.duration }} access
      </div>
    </div>

    <!-- DESCRIPTION  -->
    <div class="description">
      <div class="mgb-8">
        Full access to all examinations, diagrams & explanations.
      </div>

      <div>
        Use subscription on both website and mobile app.
      </div>
    </div>

    <!-- BUTTON  -->
    <router-link
      :to="'/select-subscription?plan=' + subscription.slug"
      class="btn btn-primary"
      >Choose plan</router-link
    >
  </div>
</template>

<script>
export default {
  name: "subscriptionCard",

  props: {
    subscription: Object,
  },
};
</script>

<style lang="scss" scoped>
.subscription-card {
  width: toRem(310);
  padding: toRem(30);
  margin: toRem(8);
  box-shadow: 0 toRem(18) toRem(80) rgba($brand-black, 0.04),
    0 toRem(11.6667) toRem(46.8519) rgba($brand-black, 0.0303704),
    0 toRem(6.93333) toRem(25.4815) rgba($brand-black, 0.0242963),
    0 toRem(3.6) toRem(13) rgba($brand-black, 0.02),
    0 toRem(1.46667) toRem(6.51852) rgba($brand-black, 0.0157037),
    0 toRem(0.333333) toRem(3.14815) rgba($brand-black, 0.00962963);

  @include breakpoint-down(xl) {
    width: toRem(285);
    margin: toRem(10);
    padding: toRem(25);
  }

  @include breakpoint-custom-down(850) {
    width: toRem(290);
    margin: toRem(12);
  }

  @include breakpoint-down(md) {
    width: toRem(350);
    padding: toRem(30);
    margin-bottom: toRem(15);
  }

  @include breakpoint-down(xs) {
    width: 100%;
  }

  .title-text {
    @include font-height(33, 38);

    @include breakpoint-down(xl) {
      @include font-height(28, 34);
    }

    @include breakpoint-down(xs) {
      @include font-height(27, 33);
    }
  }

  .amount-row {
    @include flex-row-start-nowrap;
    margin-bottom: toRem(25);

    .amount {
      @include font-height(21, 35);
      margin-right: toRem(8);

      @include breakpoint-down(xl) {
        @include font-height(18, 33);
      }

      @include breakpoint-down(xs) {
        @include font-height(16, 30);
      }
    }

    .duration {
      padding-left: toRem(8);
      @include font-height(16, 18);
      border-left: toRem(1) solid $light-grey-text;

      @include breakpoint-down(xl) {
        @include font-height(15, 17);
      }

      @include breakpoint-down(lg) {
        @include font-height(14, 16);
      }
    }
  }

  .description {
    @include font-height(15, 23);
    margin-bottom: toRem(30);
    color: $grey-accent;

    @include breakpoint-down(xl) {
      @include font-height(14.5, 22);
    }

    @include breakpoint-down(lg) {
      @include font-height(14, 20);
    }
  }

  .btn {
    width: max-content;
    padding: toRem(14.5) toRem(25);
  }
}
</style>

<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <page-loader :loader_text="loader_text" v-if="show_page_loader" />

    <!-- PORTAL TARGET -->
    <portal-target name="54-modals"> </portal-target>
  </div>
</template>

<script>
import pageLoader from "@/shared/components/page-loader";

export default {
  name: "App",

  metaInfo: {
    title: "Examination Preparatory System",
    titleTemplate: "%s - Class54",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    pageLoader,
  },

  created() {
    this.$bus.$on("show-page-loader", (msg) => this.togglePageLoader(msg));
    this.$bus.$on("hide-page-loader", () => (this.show_page_loader = false));
  },

  data: () => ({
    show_page_loader: false,
    loader_text: "Please wait",
  }),

  methods: {
    togglePageLoader(msg) {
      this.show_page_loader = !this.show_page_loader;
      this.loader_text = msg;
    },
  },
};
</script>

<style lang="scss">
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.3s ease;
}
.fade-leave-active {
  transition: opacity 0.3s ease;
  opacity: 0;
}
</style>

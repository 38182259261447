<template>
  <div class="exam-card rounded-4 h-auto">
    <!-- TEXT  -->
    <div class="text blackish-grey font-weight-700">{{ title }}</div>

    <!-- ICON  -->
    <div class="icon icon-angle-arrow-right"></div>
  </div>
</template>

<script>
export default {
  name: "examCard",

  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.exam-card {
  @include flex-row-between-nowrap;
  box-shadow: 0 0 toRem(15) toRem(1) rgba(0, 38, 19, 0.1);
  border: toRem(1) solid #e0e0e0;
  margin: toRem(12) toRem(7);
  padding: toRem(20) toRem(24);
  width: toRem(300);

  @include breakpoint-custom-down(850) {
    width: toRem(320);
  }

  @include breakpoint-down(md) {
    width: 90%;
  }

  @include breakpoint-down(xs) {
    width: 100%;
  }

  .text {
    text-transform: uppercase;
    font-size: toRem(12.75);

    @include breakpoint-down(xs) {
      font-size: toRem(12.35);
    }
  }

  .icon {
    color: #6fcf97;
    font-size: toRem(16);
  }
}
</style>

import { $serviceApi as api } from "@/services/apiHelper";

const routes = {
  register: "api/register",
  login: "api/login",
  decode_token: "api/decode-token",
  request_password: "api/password/otp",
  reset_password: "api/reset-password-otp",
  resend_otp: "api/resend-otp",
  verify_otp: "api/verify-otp",
  logout: "api/logout",
};

export default {
  async registerNewUser({ commit }, payload) {
    let response = await api.userService().push(routes.register, payload);

    if (response.status_code === 200) {
      localStorage.setItem("falseEntryToken", response.data.token);
      localStorage.timestamp = +new Date();
      commit("AUTH_SUCCESS", response.data);
    }
    return response;
  },

  async loginUser({ commit }, payload) {
    let response = await api.userService().push(routes.login, payload);

    if (response.status_code === 200) {
      localStorage.setItem("falseEntryToken", response.data.token);
      localStorage.timestamp = +new Date();
      commit("AUTH_SUCCESS", response.data);
    }
    return response;
  },

  async decodeToken({ commit }, token) {
    let response = await api
      .userService()
      .fetch(`${routes.decode_token}?token=${token}`);

    if (response.status_code === 200) {
      localStorage.setItem("falseEntryToken", response.data.token);
      localStorage.timestamp = +new Date();
      commit("AUTH_SUCCESS", response.data);
    }
    return response;
  },

  async requestUserPassword(_, payload) {
    return await api.userService().push(routes.request_password, payload);
  },

  async resetUserPassword(_, payload) {
    return await api.userService().push(routes.reset_password, payload);
  },

  async resendPhoneOTP(_, phone) {
    return await api.userService().push(routes.resend_otp, { phone });
  },

  async verifyPhoneOTP(_, payload) {
    return await api.userService().push(routes.verify_otp, payload);
  },

  async logoutUser({ commit }) {
    let response = await api.userService().push(routes.logout);

    if (response.data.status_code === 200) {
      localStorage.clear();
      commit("AUTH_LOGOUT");
    }
    return response;
  },
};

<template>
  <div class="privacy-content">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="row">
        <div class="col-12 col-md-8 mx-auto">
          <div class="content mx-auto">
            <div class="title-text">Introduction</div>

            <div class="text-content">
              Thank you for choosing to be part of our community at class54
              (“Company”, “we”, “us”, or “our”). We are committed to protecting
              your personal information and your right to privacy. If you have
              any questions or concerns about our policy or our practices with
              regards to your personal information, please contact us at
              info@class54.com.
            </div>

            <div class="text-content">
              When you visit our mobile application and use our services, you
              trust us with your personal information. We take your privacy very
              seriously. In this privacy policy, we seek to explain to you in
              the clearest way possible what information we collect, how we use
              it and what rights you have in relation to it. We hope you take
              some time to read through it carefully, as it is important. If
              there are any terms in this privacy policy that you do not agree
              with, please discontinue the use of our Apps and our services.
            </div>

            <div class="text-content">
              This privacy policy applies to all information collected through
              our mobile application, ("Apps"), and/or any related services,
              sales, marketing or events (we refer to them collectively in this
              privacy policy as the "Services"). Please read this privacy policy
              carefully as it will help you make informed decisions about
              sharing your personal information with us.
            </div>

            <!-- ITEMS  -->
            <div class="list-items">
              <div class="item">1. &nbsp; What Information Do We Collect?</div>
              <div class="item">2. &nbsp; How Do We Use Your Information?</div>
              <div class="item">
                3. &nbsp; Will Your Information Be Shared With Anyone?
              </div>
              <div class="item">
                4. &nbsp; How Do We Handle Your Social Logins?
              </div>
              <div class="item">
                5. &nbsp; How Long Do We Keep Your Information?
              </div>
              <div class="item">
                6. &nbsp; How Do We Keep Your Information Safe?
              </div>
              <div class="item">7. &nbsp; What Are Your Privacy Rights?</div>
              <div class="item">
                8. &nbsp; Controls For Do-not-track Features
              </div>
              <div class="item">
                9. &nbsp; Do We Make Updates To This Policy?
              </div>
              <div class="item">
                10. &nbsp; How Can You Contact Us About This Policy?
              </div>
            </div>

            <!-- EXPLANATION  -->
            <div class="title-text">
              1. &nbsp; What Information Do We Collect?
            </div>

            <div class="text-content">
              The personal information you disclose to us <br />
              In Short: We collect personal information that you provide to us
              such as name, address, contact information, passwords and security
              data, payment information, and social media login data.
            </div>

            <div class="text-content">
              We collect personal information that you voluntarily provide to us
              when registering at the Apps, expressing an interest in obtaining
              information about us or our products and services, when
              participating in activities on the Apps (such as posting messages
              in our online forums or entering competitions, contests or
              giveaways) or otherwise contacting us.
            </div>

            <div class="text-content">
              The personal information that we collect depends on the context of
              your interactions with us and the Apps, the choices you make and
              the products and features you use. The personal information we
              collect can include the following:
            </div>

            <div class="text-content">
              Publicly Available Personal Information. We collect first name,
              maiden name, last name, nickname, phone numbers, email addresses,
              and other similar data.
            </div>

            <div class="text-content">
              Credentials. We collect passwords, password hints, and similar
              security information used for authentication and account access.
            </div>

            <div class="text-content">
              Payment Data. We collect data necessary to process your payment if
              you make purchases, such as your payment instrument number (such
              as a credit card number), and the security code associated with
              your payment instrument. All payment data is stored by Paystack.
              You may find their privacy policy link(s) here:
              https://paystack.com/terms.
            </div>

            <div class="text-content">
              Social Media Login Data. We provide you with the option to
              register using social media account details, like your Facebook,
              Twitter or other social media account. If you choose to register
              in this way, we will collect the Information described in the
              section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below.<br />
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </div>

            <div class="text-content">
              Information automatically collected In Short: Some information –
              such as IP address and/or browser and device characteristics – is
              collected automatically when you visit our Apps. We automatically
              collect certain information when you visit, use or navigate the
              Apps. This information does not reveal your specific identity
              (like your name or contact information) but may include device and
              usage information, such as your IP address, browser and device
              characteristics, operating system, language preferences, referring
              URLs, device name, country, location, information about how and
              when you use our Apps and other technical information. This
              information is primarily needed to maintain the security and
              operation of our Apps, and for our internal analytics and
              reporting purposes.
            </div>

            <div class="text-content">
              Information collected through our Apps In Short: We may collect
              information regarding your mobile device, push notifications when
              you use our apps. If you use our Apps, we may also collect the
              following information: Mobile Device Access. We may request access
              or permission to certain features from your mobile device,
              including your mobile device’s camera, reminders, storage,
              bluetooth, social media accounts, internet connection, read phone
              state, and other features. If you wish to change our access or
              permissions, you may do so in your device’s settings.
            </div>

            <div class="text-content">
              Mobile Device Data. We may automatically collect device
              information (such as your mobile device ID, model, and
              manufacturer), operating system, version information, and IP
              address. Push Notifications. We may request to send you to push
              notifications regarding your account or the mobile application. If
              you wish to opt-out from receiving these types of communications,
              you may turn them off in your device’s settings.
            </div>

            <div class="title-text">
              2. &nbsp; How Do We Use Your Information?
            </div>

            <div class="text-content">
              In Short: We process your information for purposes based on
              legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent. We use personal information collected via our Apps for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </div>

            <div class="text-content">
              We use the information we collect or receive: To facilitate
              account creation and logon process. If you choose to link your
              account with us to a third party account (such as your Google or
              Facebook account), we use the information you allowed us to
              collect from those third parties to facilitate account creation
              and logon process for the performance of the contract. See the
              section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for
              further information. To send you marketing and promotional
              communications. We and/or our third-party marketing partners may
              use the personal information you send to us for our marketing
              purposes if this is in accordance with your marketing preferences.
              You can opt-out of our marketing emails at any time (see the "WHAT
              ARE YOUR PRIVACY RIGHTS" below).
            </div>

            <div class="text-content">
              To send you marketing and promotional communications. We and/or
              our third-party marketing partners may use the personal
              information you send to us for our marketing purposes if this is
              in accordance with your marketing preferences. You can opt-out of
              our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS" below).
            </div>

            <div class="text-content">
              Fulfill and manage your orders. We may use your information to
              fulfill and manage your orders, payments, returns, and exchanges
              made through the Apps.
            </div>

            <div class="text-content">
              To enable user-to-user communications. We may use your information
              in order to enable user-to-user communications with each user's
              consent.
            </div>

            <div class="title-text">
              3. &nbsp; Will Your Information Be Shared With Anyone?
            </div>

            <div class="text-content">
              In Short: We only share information with your consent, to comply
              with laws, to provide you with services, to protect your rights,
              or to fulfill business obligations.
            </div>

            <div class="text-content">
              We may process or share data based on the following legal basis:
            </div>

            <div class="text-content">
              Consent: We may process your data if you have given us specific
              consent to use your personal information for a specific purpose.
            </div>

            <div class="text-content">
              Legitimate Interests: We may process your data when it is
              reasonably necessary to achieve our legitimate business interests.
            </div>

            <div class="text-content">
              Performance of a Contract: Where we have entered into a contract
              with you, we may process your personal information to fulfill the
              terms of our contract.
            </div>

            <div class="text-content">
              Legal Obligations: We may disclose your information where we are
              legally required to do so in order to comply with applicable law,
              governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              (including in response to public authorities to meet national
              security or law enforcement requirements).
            </div>

            <div class="text-content">
              Vital Interests: We may disclose your information where we believe
              it is necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved. More specifically, we may need to process your data or
              share your personal information in the following situations:
              Vendors, Consultants, and Other Third-Party Service Providers. We
              may share your data with third-party vendors, service providers,
              contractors or agents who perform services for us or on our behalf
              and require access to such information to do that work. Examples
              include payment processing, data analysis, email delivery, hosting
              services, customer service, and marketing efforts. We may allow
              selected third parties to use tracking technology on the Apps,
              which will enable them to collect data about how you interact with
              the Apps over time. This information may be used to, among other
              things, analyze and track data, determine the popularity of
              certain content and better understand online activity. Unless
              described in this Policy, we do not share, sell, rent or trade any
              of your information with third parties for their promotional
              purposes.
            </div>

            <div class="text-content">
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company. Third-Party Advertisers. We may
              use third-party advertising companies to serve ads when you visit
              the Apps. These companies may use information about your visits to
              our Website(s) and other websites that are contained in web
              cookies and other tracking technologies in order to provide
              advertisements about goods and services of interest to you.
            </div>

            <div class="text-content">
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy
              policy. Affiliates include our parent company and any
              subsidiaries, joint venture partners or other companies that we
              control or that are under common control with us. <br />
              Business Partners. We may share your information with our business
              partners to offer you certain products, services or promotions.
            </div>

            <div class="text-content">
              Other Users. When you share personal information (for example, by
              posting comments, contributions or other content to the Apps) or
              otherwise interact with public areas of the Apps, such personal
              information may be viewed by all users and may be publicly
              distributed outside the Apps in perpetuity. If you interact with
              other users of our Apps and register through a social network
              (such as Facebook), your contacts on the social network will see
              your name, profile photo, and descriptions of your activity.
              Similarly, other users will be able to view descriptions of your
              activity, communicate with you within our Apps, and view your
              profile.
            </div>

            <div class="title-text">
              4. &nbsp; How Do We Handle Your Social Logins?
            </div>

            <div class="text-content">
              In Short: If you choose to register or log in to our services
              using a social media account, we may have access to certain
              information about you.
            </div>

            <div class="text-content">
              Our Apps offer you the ability to register and log in using your
              third party social media account details (like your Facebook or
              Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile Information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, e-mail address, friends list, profile picture as well as
              other information you choose to make public.
            </div>

            <div class="text-content">
              We will use the information we receive only for the purposes that
              are described in this privacy policy or that are otherwise made
              clear to you on the Apps. Please note that we do not control, and
              are not responsible for, other uses of your personal information
              by your third party social media provider. We recommend that you
              review their privacy policy to understand how they collect, use
              and share your personal information, and how you can set your
              privacy preferences on their sites and apps.
            </div>

            <div class="title-text">
              5. &nbsp; How Long Do We Keep Your Information?
            </div>

            <div class="text-content">
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy policy unless
              otherwise required by law. We will only keep your personal
              information for as long as it is necessary for the purposes set
              out in this privacy policy unless a longer retention period is
              required or permitted by law (such as tax, accounting or other
              legal requirements).
            </div>

            <div class="text-content">
              No purpose in this policy will require us keeping your personal
              information for longer than When we have no ongoing legitimate
              business need to process your personal information, we will either
              delete or anonymize it, or, if this is not possible (for example,
              because your personal information has been stored in backup
              archives), then we will securely store your personal information
              and isolate it from any further processing until deletion is
              possible.
            </div>

            <div class="title-text">
              6. &nbsp; How Do We Keep Your Information Safe?
            </div>

            <div class="text-content">
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.
            </div>

            <div class="text-content">
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, please also remember that we
              cannot guarantee that the internet itself is 100% secure. Although
              we will do our best to protect your personal information, the
              transmission of personal information to and from our Apps is at
              your own risk. You should only access the services within a secure
              environment.
            </div>

            <div class="title-text">
              7. &nbsp; What are your privacy rights?
            </div>

            <div class="text-content">
              In Short: You may review, change, or terminate your account at any
              time.
            </div>

            <div class="text-content">
              Account Information<br />
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:<br />
              Contact us using the contact information provided. Upon your
              request to terminate your account, we will deactivate or delete
              your account and information from our active databases. However,
              some information may be retained in our files to prevent fraud,
              troubleshoot problems, assist with any investigations, enforce our
              Terms of Use and/or comply with legal requirements.
            </div>

            <div class="text-content">
              Opting out of email marketing: You can unsubscribe from our
              marketing email list at any time by clicking on the unsubscribe
              link in the emails that we send or by contacting us using the
              details provided below. You will then be removed from the
              marketing email list – however, we will still need to send you
              service-related emails that are necessary for the administration
              and use of your account. To otherwise opt-out, you may:
            </div>

            <div class="title-text">
              8. &nbsp; Controls for DO-NOT-TRACK Features
            </div>

            <div class="text-content">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized.
            </div>

            <div class="text-content">
              As such, we do not currently respond to DNT browser signals or any
              other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted
              that we must follow in the future, we will inform you about that
              practice in a revised version of this privacy policy.
            </div>

            <div class="title-text">
              9. &nbsp; Do we make updates to this policy?
            </div>

            <div class="text-content">
              In Short: Yes, we will update this policy as necessary to stay
              compliant with relevant laws. We may update this privacy policy
              from time to time. The updated version will be indicated by an
              updated “Revised” date and the updated version will be effective
              as soon as it is accessible. If we make material changes to this
              privacy policy, we may notify you either by prominently posting a
              notice of such changes or by directly sending you a notification.
              We encourage you to review this privacy policy frequently to be
              informed of how we are protecting your information.
            </div>

            <div class="title-text">
              10. &nbsp; How can you contact us about this policy?
            </div>

            <div class="text-content">
              If you have questions or comments about this policy, you may email
              us at info@class54.com
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyContent",
};
</script>

<style lang="scss" scoped>
.privacy-content {
  padding: toRem(56) 0;
  @include font-height(15.25, 26);
  font-weight: 400;
  color: $blackish-grey;

  @include breakpoint-down(xs) {
    @include font-height(14, 24);
  }

  .content {
    .title-text {
      font-weight: 700;
      margin-bottom: toRem(10);
    }

    .text-content {
      margin-bottom: toRem(22);
    }

    .list-items {
      margin-bottom: toRem(38);

      .item {
        margin-bottom: toRem(5);
      }
    }
  }
}
</style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.415 7.34961C15.1255 7.34977 14.8451 7.45028 14.6214 7.63402C14.3978 7.81775 14.2448 8.07335 14.1885 8.35725C14.1321 8.64116 14.176 8.93581 14.3126 9.19101C14.4491 9.4462 14.67 9.64615 14.9374 9.75679C15.2049 9.86743 15.5024 9.88191 15.7794 9.79776C16.0563 9.71362 16.2955 9.53606 16.4562 9.29533C16.6169 9.0546 16.6892 8.7656 16.6607 8.47757C16.6322 8.18954 16.5047 7.92029 16.3 7.71571C16.1839 7.59937 16.0459 7.50713 15.894 7.4443C15.7421 7.38147 15.5793 7.34929 15.415 7.34961Z"
      fill="#29CC7A"
    />
    <path
      d="M22.3802 2.34646C22.38 2.34506 22.38 2.34364 22.3802 2.34224C22.3406 2.16848 22.2531 2.00926 22.1278 1.88253C22.0025 1.75581 21.8443 1.66663 21.671 1.62505C20.2741 1.28427 18.0766 1.64755 15.6414 2.62255C13.1871 3.60693 10.8874 5.06005 9.33301 6.61724C8.83482 7.11284 8.37197 7.64273 7.94785 8.20302C6.90113 8.15614 5.9791 8.30521 5.20238 8.64411C2.49582 9.83521 1.72285 12.8835 1.51707 14.135C1.48729 14.3129 1.49895 14.4952 1.55114 14.6678C1.60333 14.8404 1.69464 14.9986 1.81796 15.1301C1.94129 15.2617 2.0933 15.363 2.26217 15.4261C2.43105 15.4893 2.61222 15.5127 2.7916 15.4944H2.79769L5.81269 15.1654C5.81644 15.2038 5.82066 15.239 5.82394 15.2713C5.86267 15.6387 6.02651 15.9816 6.288 16.2426L7.75941 17.7149C8.01997 17.9768 8.36281 18.1408 8.73019 18.1794L8.83097 18.1902L8.50285 21.2015V21.2076C8.48608 21.3697 8.50334 21.5336 8.55352 21.6887C8.6037 21.8439 8.68569 21.9868 8.79427 22.1085C8.90284 22.2301 9.0356 22.3277 9.18406 22.3951C9.33252 22.4625 9.49341 22.4982 9.65644 22.4999C9.72162 22.5 9.78669 22.4947 9.85097 22.484C11.1096 22.2815 14.1564 21.5179 15.3443 18.7968C15.6803 18.0252 15.8289 17.1069 15.7872 16.0621C16.35 15.6389 16.8819 15.176 17.3786 14.6769C18.9457 13.1179 20.4035 10.8369 21.3775 8.41818C22.3474 6.01068 22.7125 3.79677 22.3802 2.34646ZM17.3618 10.544C16.9766 10.9295 16.4857 11.1922 15.9513 11.2987C15.4168 11.4052 14.8628 11.3508 14.3592 11.1424C13.8557 10.934 13.4252 10.5809 13.1224 10.1278C12.8196 9.67472 12.6579 9.14198 12.6579 8.597C12.6579 8.05202 12.8196 7.51929 13.1224 7.0662C13.4252 6.6131 13.8557 6.26001 14.3592 6.05158C14.8628 5.84316 15.4168 5.78877 15.9513 5.8953C16.4857 6.00183 16.9766 6.26449 17.3618 6.65005C17.6199 6.90433 17.8249 7.20741 17.9649 7.54166C18.1048 7.87591 18.1769 8.23465 18.1769 8.597C18.1769 8.95936 18.1048 9.3181 17.9649 9.65235C17.8249 9.9866 17.6199 10.2897 17.3618 10.544Z"
      fill="#29CC7A"
    />
    <path
      d="M7.59023 18.1153C7.40884 18.0928 7.22548 18.1373 7.0746 18.2404C6.77507 18.4453 6.47413 18.6478 6.17038 18.8451C5.55585 19.2445 4.82085 18.5433 5.18601 17.9076L5.75554 16.9233C5.83696 16.8041 5.8823 16.6641 5.88612 16.5198C5.88994 16.3756 5.85207 16.2333 5.77706 16.1101C5.70205 15.9868 5.59308 15.8878 5.46322 15.825C5.33336 15.7621 5.18812 15.738 5.04491 15.7556C4.4128 15.8355 3.82524 16.1235 3.37476 16.574C3.2032 16.7461 2.68148 17.2683 2.4007 19.2581C2.32078 19.8297 2.2704 20.4049 2.24976 20.9817C2.24719 21.0818 2.2647 21.1814 2.30124 21.2746C2.33778 21.3678 2.39261 21.4528 2.46252 21.5245C2.53242 21.5962 2.61597 21.6532 2.70824 21.692C2.80051 21.7309 2.89963 21.7509 2.99976 21.7509H3.01851C3.5957 21.7305 4.17145 21.6804 4.74351 21.6009C6.73429 21.3197 7.25648 20.7975 7.42804 20.6259C7.88067 20.1752 8.1679 19.5849 8.2432 18.9506C8.2673 18.7533 8.21205 18.5544 8.08961 18.3978C7.96717 18.2412 7.78755 18.1395 7.59023 18.1153Z"
      fill="#29CC7A"
    />
  </svg>
</template>

<script>
export default {
  name: "PricingBasicPlan",
};
</script>

export default {
  AUTH_SUCCESS: (state, response) => {
    let userData = {};

    userData.id = response.id;
    userData.fullname = `${response.first_name} ${response.last_name}`;
    userData.email = response.email;
    userData.phone = response.phone;
    userData.gender = response.gender;
    userData.image = response.image_url;

    state.authUser = userData;
    localStorage.setItem("validAuthUser", JSON.stringify(state.authUser));
  },

  AUTH_LOGOUT: (state) => {
    state.token = "";
    state.authUser = "";
  },
};

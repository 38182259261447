export const aboutFaqs = [
  {
    title: "How can Class54 help me to prepare for my examination?",
    value:
      "Yes, Class54 can help you as long as we have content for your planned examination. All you have to do is to download and install either our mobile app or the Windows desktop software to get started. We have a rich plethora of well-explained past and curated questions by experts and they will surely help you prepare for and pass your examinations.",
  },
  {
    title: "How much does it cost to access Class54?",
    value:
      "It costs a token of NGN1,500 for One-month access, NGN2,500 for 3-months access, and NGN4,000 for 1-year access. Our goal is to make examination preparation accessible for every person on earth and so we have made Class54 very affordable for you.",
  },
  {
    title: "How many exams can I access after subscribing to Class54?",
    value:
      "Once you subscribe to use Class54 on mobile or on the web, you will have unlimited access to all the exams on the platform and to any other features that we may introduce to the platform.",
  },
  {
    title: "How do I subscribe to Class54?",
    value:
      "You can subscribe automatically by using your debit card through the app or on the web. Or you can simply do a bank transfer to any of our bank accounts after which you can call/text us so that we can activate your subscription quickly. ",
  },
  {
    title: "On which devices can I use Class54?",
    value:
      "You can install the Class54 mobile app on any android device (version 10 and above) and on any IOS device. The JAMB/UTME desktop software, that can only be installed on a Windows PC.",
  },
  {
    title: "What happens to my subscription if I change my device?",
    value:
      "Your account is tied to your registered phone number, therefore, you can easily move your account/subscription to any other device as you need to. In the case of the JAMB/UTME desktop software, you will have to send us your new serial number in order for us to re-activate your subscription on the new device.",
  },
];

export const pricingFaqs = [
  {
    title: "Can I subscribe without registering or having an account?",
    value:
      "No, you need to create a Class54 account to subscribe. The good news is, registration only takes a few seconds. You can sign up <a href='app.class54.com' class='green-link-text'>here</a> on the website, or through the Class54 <a href='https://play.google.com/store/apps/details?id=com.class54.mobile' target='_blank' class='green-link-text'>Android</a> or <a href='https://apps.apple.com/us/app/class54/id1622971742' class='green-link-text' target='_blank'>iOS</a> apps.",
  },
  {
    title: "How do I subscribe?",
    value:
      "<div>Once you've created your Class54 account, you can subscribe by selecting either a bank transfer or Debit card payment option. Most payments and subscription confirmations are automated, but you can also choose manual processing. If you opt for manual, our support team will verify your payment and activate your subscription. We respond to subscription inquiries as quickly as possible.</div><div> <div class='header-text'>Subscription plans</div><ul><li>1-month subscription: ₦1,500</li><li>3-months subscription: ₦2,500</li><li>1-year subscription: ₦4,000</li></ul></div>",
  },
  {
    title: "Does the subscription cover the offline desktop software?",
    value:
      "No, our <router-link to='/jamb-software-download' class='green-link-text'>offline JAMB desktop software</router-link> has a separate pricing model. Since it operates fully offline and isn't connected to your online account, it costs N2,000 for one year.",
  },
  {
    title: "Does the Class54 subscription expire?",
    value:
      "Yes, your subscription expires because we make yearly updates based on feedback from our users. We also find that most of our users don’t need the app beyond a year, as they typically pass their exams within that timeframe — which is our goal!",
  },
  {
    title: "Which exams does the subscription cover on the Class54 app?",
    value:
      "All subscriptions, whether for one month, three months, or one year, provide full access to JAMB, WAEC, and POST-UTME. You'll also get unlimited access to JAMB Study Notes, performance analytics, smart voice assistance, and other valuable features. Class54 is your all-in-one solution for securing university admission and your desired course.",
  },
  {
    title:
      "How do I contact you in case I have inquiries or issues subscribing?",
    value:
      "If you have any questions or experience any issues during the subscription process, you can reach our support team through email at <a href='mailto:info@class54.com' class='dark-link-text'>info@class54.com</a> or directly through the WhatsApp <a href='tel:+2348167042330' class='dark-link-text'>+2348167042330</a>, <a href='mailto:+2349035409551' class='dark-link-text'>+2349035409551</a>. We're here to help and will respond as quickly as possible.",
  },
];

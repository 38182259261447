export default (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("falseEntryToken") === null) {
      next({
        name: "Class54Login",
      });
    } else if (localStorage.getItem("falseEntryToken") !== null) next();
  }
  // VERIFY IF ROUTE IS A JUST A GUEST
  else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("falseEntryToken") === null) next();
    else next({ name: "SelectSubscription" });
  } else next();
};

<template>
  <div class="pricing-card" :class="[getBorderStyle, getActiveCard]">
    <!-- ACTIVE TAG -->
    <div
      class="active-card-tag rounded-50 brand-green-bg brand-white font-weight-600 text-center"
      v-if="index === 1"
    >
      Most Popular
    </div>

    <!-- PRICING ICON -->
    <div class="pricing-icon">
      <component :is="getPricingIcon" />
    </div>

    <!-- PRICING PLAN -->
    <div class="pricing-plan">
      {{ pricing_data.plan_type }}
    </div>

    <!-- PRICING DESCRIPTION -->
    <div class="pricing-description">
      {{ pricing_data.plan_description }}
    </div>

    <!-- PRICING AMOUNT DURATION -->
    <div class="pricing-amount-duration">
      <div class="amount">{{ pricing_data.plan_amount }}</div>
      <div class="duration">/{{ pricing_data.plan_duration }}</div>
    </div>

    <!-- PRICING ACTION -->
    <div class="pricing-action">
      <button class="btn btn-primary w-100" @click="togglePricingDownload">
        Get started now
      </button>
    </div>

    <portal to="54-modals">
      <transition name="fade" v-if="show_pricing_download">
        <pricing-download-modal @closeTriggered="togglePricingDownload" />
      </transition>
    </portal>
  </div>
</template>

<script>
import pricingBasicIcon from "@/modules/landing/components/pricing-comps/pricing-basic-plan-icon";
import pricingPremiumIcon from "@/modules/landing/components/pricing-comps/pricing-premium-plan-icon";
import pricingPlatinumIcon from "@/modules/landing/components/pricing-comps/pricing-platinum-plan-icon";

export default {
  name: "PricingCard",

  components: {
    pricingBasicIcon,
    pricingPremiumIcon,
    pricingPlatinumIcon,
    pricingDownloadModal: () =>
      import(
        /* webpackChunkName: "modal" */ "@/modules/landing/modals/pricing-download-modal"
      ),
  },

  props: {
    index: Number,
    pricing_data: {
      type: Object,
      required: true,
      default: () => ({
        plan_type: "Basic",
        plan_description: "",
        plan_amount: "",
        plan_duration: "",
        plan_action: "",
      }),
    },
  },

  computed: {
    getPricingIcon() {
      switch (this.pricing_data.plan_type.toLowerCase()) {
        case "basic":
          return pricingBasicIcon;
        case "premium":
          return pricingPremiumIcon;
        case "platinum":
          return pricingPlatinumIcon;
        default:
          return pricingBasicIcon;
      }
    },

    getBorderStyle() {
      if (this.index === 0) return "rounded-left-24";
      else if (this.index === 2) return "rounded-right-24";
      else return "";
    },

    getActiveCard() {
      if (this.index === 1) return "pricing-active-card";
    },
  },

  data() {
    return {
      show_pricing_download: false,
    };
  },

  methods: {
    togglePricingDownload() {
      this.show_pricing_download = !this.show_pricing_download;
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-card {
  border: toRem(1) solid #e0e0e0;
  padding: toRem(40) toRem(24);
  background: $brand-white;
  position: relative;

  @include breakpoint-down(md) {
    border-radius: toRem(24) !important;
  }

  .pricing-icon {
    margin-bottom: toRem(16);
  }

  .pricing-plan {
    @include font-height(30, 48);
    margin-bottom: toRem(4);
    color: $brand-black;
    font-weight: 600;
  }

  .pricing-description {
    @include font-height(15, 22);
    margin-bottom: toRem(32);
    color: #464646;
  }

  .pricing-amount-duration {
    @include flex-row-start-nowrap;
    margin-bottom: toRem(38);
    align-items: flex-end;
    position: relative;
    font-weight: 700;

    .amount {
      @include font-height(38, 40);
      margin-right: toRem(2);
      color: $brand-black;
    }

    .duration {
      position: relative;
      @include font-height(18, 26);
      font-weight: 600;
      color: #464646;
      top: toRem(-3);
    }
  }

  .pricing-action {
    .btn {
      padding: toRem(20) 0;
    }
  }
}

.pricing-active-card {
  border: toRem(2) solid #29cc7a;
  background: #e7fcf2;

  .active-card-tag {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: toRem(-18);
    padding: toRem(10) toRem(20);
    @include font-height(15.5, 14);
  }
}
</style>

<template>
  <div class="form-card-wrapper">
    <div
      class="form-card brand-white-bg rounded-4 w-100"
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="40"
      data-aos-duration="950"
      data-aos-easing="ease-in-out"
    >
      <!-- BACK LINK -->
      <template v-if="form_data.show_back_link">
        <a
          :href="form_data.back_link_route"
          class="back-link"
          title="Go Back"
          v-if="form_data.back_link_route"
        >
          <!-- ICON  -->
          <div class="avatar">
            <div class="icon icon-caret-left"></div>
          </div>

          <!-- BACK TEXT  -->
          <div class="text">Back</div>
        </a>

        <div
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          class="back-link pointer"
          title="Go Back"
          v-else
        >
          <!-- ICON  -->
          <div class="avatar">
            <div class="icon icon-caret-left"></div>
          </div>

          <!-- BACK TEXT  -->
          <div class="text">Back</div>
        </div>
      </template>

      <!-- HEADER TEXT -->
      <template v-if="form_data.header">
        <div class="header-text brand-dark-blue">
          {{ form_data.header }}
        </div>
      </template>

      <!-- DESCRIPTION TEXT -->
      <template v-if="form_data.description">
        <div class="description-text grey-accent">
          {{ form_data.description }}
        </div>
      </template>

      <!-- BODY CONTENT -->
      <template>
        <slot name="body-content"></slot>
      </template>
    </div>

    <!-- HELP TEXT -->
    <template v-if="form_data.footer_text">
      <div class="w-100 d-flex justify-content-center">
        <router-link
          :to="form_data.footer_link"
          class="help-text"
          v-if="form_data.footer_link"
          >{{ form_data.footer_text }}</router-link
        >

        <div
          class="help-text link-no-underline grey-accent font-weight-500"
          v-else
        >
          {{ form_data.footer_text }}
        </div>
      </div>
    </template>

    <!-- HELP FOOTER TEXT INFO LINK -->
    <template v-if="form_data.bottom_link_text">
      <div class="d-flex justify-content-center">
        <div class="help-text dark-text text-center link-no-underline">
          <span class="mgr-2" v-if="form_data.bottom_text">
            {{ form_data.bottom_text }}
          </span>

          <router-link
            :to="form_data.bottom_link"
            v-if="form_data.bottom_link"
            class="footer-link link-underline"
            >{{ form_data.bottom_link_text }}</router-link
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "formCard",

  props: {
    form_data: {
      type: Object,
      default: () => ({
        show_back_link: true,
        back_link_route: "",
        header: "Header Text",
        description: "Description text goes here...",
        footer_text: "",
        footer_link: "",
        bottom_text: "",
        bottom_link_text: "",
        bottom_link: "",
      }),
    },
  },

  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<style lang="scss">
.form-card-wrapper {
  .form-card {
    padding: toRem(34) toRem(30);
    box-shadow: 0px 18px 80px rgba(0, 0, 0, 0.04),
      0px 11.6667px 46.8519px rgba(0, 0, 0, 0.0303704),
      0px 6.93333px 25.4815px rgba(0, 0, 0, 0.0242963),
      0px 3.6px 13px rgba(0, 0, 0, 0.02),
      0px 1.46667px 6.51852px rgba(0, 0, 0, 0.0157037),
      0px 0.333333px 3.14815px rgba(0, 0, 0, 0.00962963);

    @include breakpoint-custom-down(380) {
      padding: toRem(20);
    }

    .back-link {
      @include flex-row-start-nowrap;
      margin-bottom: toRem(24);
      margin-left: toRem(-7);
      width: max-content;
      font-weight: 600;

      .avatar {
        @include square-shape(28);

        .icon {
          color: $brand-accent-green;
          @include center-placement;
          @include transition(0.4s);
          font-size: toRem(14);
        }
      }

      .text {
        color: $brand-accent-green;
        @include transition(0.4s);
        text-decoration: underline;
        font-size: toRem(14.5);

        @include breakpoint-custom-down(380) {
          font-size: toRem(14);
        }
      }

      &:hover {
        .text,
        .icon {
          color: $brand-dark-green;
          text-decoration: none;
        }
      }
    }

    .header-text {
      @include font-height(25, 30);
      margin-bottom: toRem(4);
      font-weight: 900;

      @include breakpoint-custom-down(380) {
        @include font-height(23, 28);
      }
    }

    .description-text {
      @include font-height(14.5, 24);
      margin-bottom: toRem(24);

      @include breakpoint-custom-down(380) {
        @include font-height(13.5, 22);
      }
    }
  }

  .help-text {
    @include font-height(14.5, 18);
    color: $brand-accent-green;
    text-decoration: underline;
    @include transition(0.4s);
    margin-top: toRem(26);
    text-align: center;
    width: max-content;
    font-weight: 600;

    @include breakpoint-custom-down(380) {
      @include font-height(13.5, 20);
    }

    &:hover {
      color: $brand-dark-green;
      text-decoration: none;
    }
  }

  .footer-link {
    color: $brand-accent-green !important;
    text-decoration: underline !important;

    &:hover {
      color: $brand-dark-green !important;
      text-decoration: none;
    }
  }
}
</style>

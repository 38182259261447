<template>
  <div class="home-hero-banner">
    <div class="container px-3 px-sm-3 px-md-3 px-xl-2">
      <div class="hero-content mx-auto">
        <!-- LEFT SECTION  -->
        <div
          class="left-section"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="title-block brand-dark-blue font-weight-700">
            <div class="title-text">Superior</div>
            <div class="title-text">preparation for</div>
            <div class="title-text title-text-row" ref="textRefPoint">
              <div>excellent</div>

              <div class="highlight-wrapper">
                <div
                  class="highlight-list-wrapper"
                  :style="{ height: `${getHeight}px` }"
                >
                  <div
                    class="highlight-list"
                    :style="{ top: `${getOffsetPosition}px` }"
                  >
                    <div
                      class="title-text-highlight"
                      v-for="exam in exams"
                      :key="exam"
                    >
                      {{ exam }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="title-text">scores.</div>
          </div>

          <!-- LIST ITEMS  -->
          <div class="list-items">
            <div class="item">
              <div class="icon icon-circle-check"></div>
              <div class="text">
                Practice from the comfort of where you are.
              </div>
            </div>

            <div class="item">
              <div class="icon icon-circle-check"></div>
              <div class="text">
                Prepare 100% offline on our windows software
              </div>
            </div>

            <div class="item">
              <div class="icon icon-circle-check"></div>
              <div class="text">
                Over 23 years of practise questions with detailed explanations
              </div>
            </div>
          </div>

          <!-- TEXT INTRO  -->
          <div class="intro-wrapper-one">
            <div class="text-intro blackish-grey">
              GET STARTED WITH ANY OF OUR APPS
            </div>

            <!-- DOWNLOAD CTA ROW  -->
            <div class="btn-row">
              <!-- DESKTOP DOWNLOAD  -->
              <router-link
                v-if="false"
                to="/jamb-software-download"
                class="custom-app-btn desktop-btn"
              >
                <div class="icon icon-microsoft"></div>
                <div>
                  <div class="text-one">Download the</div>
                  <div class="text-two">Windows Software</div>
                </div>
              </router-link>

              <!-- ANDROID DOWNLOAD  -->
              <a
                href="https://play.google.com/store/apps/details?id=com.class54.mobile"
                target="_blank"
                class="custom-app-btn desktop-btn rounded-50"
              >
                <img
                  v-lazy="staticAsset('Playstore.svg', 'landing')"
                  alt="Google Playstore"
                  class="icon-img"
                />

                <div>
                  <div class="text-one text-uppercase cfont-10">Get it on</div>
                  <div class="text-two">Google Play</div>
                </div>
              </a>

              <!-- IOS DOWNLOAD -->
              <a
                href="https://apps.apple.com/us/app/class54/id1622971742"
                target="_blank"
                class="custom-app-btn rounded-50"
              >
                <div class="icon icon-apple"></div>
                <div>
                  <div class="text-one">Download on the</div>
                  <div class="text-two">App Store</div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <!-- RIGHT SECTION  -->
        <div class="right-section">
          <img
            v-lazy="staticAsset('ImageCollage.png', 'landing')"
            alt=""
            class="select-none"
            data-aos="fade-up"
            data-aos-offset="150"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          />
        </div>

        <div class="intro-wrapper-two">
          <div class="text-intro blackish-grey">
            GET STARTED WITH ANY OF OUR APPS
          </div>

          <!-- DOWNLOAD CTA ROW  -->
          <div class="btn-row">
            <!-- DESKTOP DOWNLOAD  -->
            <router-link
              v-if="false"
              to="/jamb-software-download"
              class="custom-app-btn index-9 position-relative desktop-btn"
            >
              <div class="icon icon-microsoft"></div>

              <div>
                <div class="text-one">Download the</div>
                <div class="text-two">Windows Software</div>
              </div>
            </router-link>

            <!-- ANDROID DOWNLOAD  -->
            <a
              href="https://play.google.com/store/apps/details?id=com.class54.mobile"
              target="_blank"
              class="custom-app-btn desktop-btn rounded-50"
            >
              <img
                v-lazy="staticAsset('Playstore.svg', 'landing')"
                alt="Google Playstore"
                class="icon-img"
              />

              <div>
                <div class="text-one text-uppercase cfont-10">Get it on</div>
                <div class="text-two">Google Play</div>
              </div>
            </a>

            <!-- IOS DOWNLOAD -->
            <a
              href="https://apps.apple.com/us/app/class54/id1622971742"
              target="_blank"
              class="custom-app-btn rounded-50"
            >
              <div class="icon icon-apple"></div>
              <div>
                <div class="text-one">Download on the</div>
                <div class="text-two">App Store</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeHeroBanner",

  computed: {
    getHeight() {
      return this.textHeight;
    },

    getOffsetPosition() {
      return this.offsetPosition === 0 ? 0 : `-${this.offsetPosition}`;
    },
  },

  data() {
    return {
      exams: ["JAMB", "WAEC", "POST UTME"],
      examIndex: 0,

      textHeight: 0,
      offsetPosition: 0,
    };
  },

  mounted() {
    this.calculateTextHeight();

    // Set interval for updating exam position
    this.interval = setInterval(() => {
      this.examIndex = this.examIndex === 2 ? 0 : (this.examIndex += 1);
      this.offsetPosition = this.getHeight * this.examIndex;
    }, 2000);

    // Recalculate width on window resize
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    calculateTextHeight() {
      this.$nextTick(() => {
        const refPoint = this.$refs.textRefPoint;
        if (refPoint) {
          this.textHeight = refPoint.offsetHeight;
        }
      });
    },

    handleResize() {
      this.calculateTextHeight(); // Recalculate width on resize
    },
  },
};
</script>

<style lang="scss" scoped>
.home-hero-banner {
  background: linear-gradient(180deg, $brand-sky-blue 0%, $brand-white 100%);

  .hero-content {
    @include flex-row-center-nowrap;
    align-items: flex-end;
    margin-top: toRem(5);
    padding: toRem(30) 0 toRem(78);

    @include breakpoint-down(lg) {
      padding: toRem(90) 0 toRem(76);
    }

    @include breakpoint-down(md) {
      @include flex-row-between-wrap;
      padding: toRem(120) 0 toRem(60);
    }

    @include breakpoint-down(sm) {
      padding: toRem(110) 0 toRem(55);
    }

    @include breakpoint-down(xs) {
      padding: toRem(90) 0 toRem(55);
    }

    .left-section {
      width: 60%;

      @include breakpoint-down(md) {
        width: 100%;
      }

      .title-block {
        @include flex-column-start-start;
        margin-bottom: toRem(30);
        row-gap: toRem(14);
        width: 90%;

        @include breakpoint-down(xl) {
          margin-bottom: toRem(24);
        }

        .title-text {
          @include font-height(58, 58);
          color: $brand-dark-blue;
          font-weight: 700;
          text-align: left;

          @include breakpoint-down(xl) {
            @include font-height(52, 52);
          }

          @include breakpoint-down(lg) {
            @include font-height(48, 48);
          }

          @include breakpoint-down(md) {
            @include font-height(42, 42);
          }

          @include breakpoint-down(sm) {
            @include font-height(38, 38);
          }

          @include breakpoint-down(xs) {
            @include font-height(34, 34);
          }
        }

        .title-text-row {
          @include flex-row-start-wrap;
          align-items: flex-start;
          position: relative;
          gap: toRem(14);

          @include breakpoint-down(md) {
            gap: toRem(12);
          }

          @include breakpoint-down(sm) {
            gap: toRem(10);
          }

          .highlight-wrapper {
            @include flex-column-start-start;
            position: relative;

            .highlight-list-wrapper {
              position: absolute;
              top: toRem(1);
              left: 0;
              overflow: hidden;

              .highlight-list {
                position: relative;
                @include transition(0.8s);

                .title-text-highlight {
                  color: $brand-green;
                  text-wrap: nowrap;
                }
              }
            }
          }
        }
      }

      .list-items {
        margin-bottom: toRem(45);

        @include breakpoint-down(xl) {
          margin-bottom: toRem(40);
        }

        @include breakpoint-down(md) {
          margin-bottom: 0;
        }

        .item {
          @include flex-row-start-nowrap;
          margin-bottom: toRem(16);

          @include breakpoint-down(xl) {
            margin-bottom: toRem(15);
          }

          .icon {
            margin-right: toRem(10);
            font-size: toRem(18);
            color: $brand-green;

            @include breakpoint-down(xl) {
              font-size: toRem(17);
            }

            @include breakpoint-down(sm) {
              font-size: toRem(16);
            }
          }

          .text {
            @include font-height(17.25, 25);
            color: $brand-dark-blue;

            @include breakpoint-custom-down(850) {
              @include font-height(15.75, 21);
            }

            @include breakpoint-down(sm) {
              @include font-height(15, 19);
            }

            @include breakpoint-down(xs) {
              @include font-height(14, 18);
            }
          }
        }
      }
    }

    .right-section {
      width: 46%;

      @include breakpoint-down(md) {
        width: 100%;
        margin-top: toRem(-30);
      }

      img {
        position: relative;
        width: 100%;
        height: auto;
        right: toRem(-30);

        @include breakpoint-down(md) {
          right: 0;
        }

        @include breakpoint-down(sm) {
          right: toRem(-10);
          width: 98%;
        }
      }
    }

    .text-intro {
      @include font-height(12, 16);
      margin-bottom: toRem(16);
      letter-spacing: 0.24em;

      @include breakpoint-down(xl) {
        @include font-height(11.5, 15);
      }

      @include breakpoint-down(xs) {
        @include font-height(11.25, 14);
        width: 100%;
        text-align: center;
      }
    }

    .btn-row {
      @include flex-row-start-nowrap;

      @include breakpoint-down(xs) {
        @include flex-row-center-wrap;
        width: 100%;
      }

      @include breakpoint-custom-down(390) {
        @include flex-column-center;
      }

      .custom-app-btn {
        @include breakpoint-custom-down(390) {
          margin-right: 0 !important;
          margin-bottom: toRem(15);
        }
      }
    }

    .intro-wrapper-one {
      @include breakpoint-down(md) {
        display: none;
      }
    }

    .intro-wrapper-two {
      display: none;

      @include breakpoint-down(md) {
        margin-top: toRem(45);
        display: unset;
        width: 100%;
      }
    }
  }
}

.desktop-btn {
  margin-right: toRem(24);

  @include breakpoint-custom-down(397) {
    margin-right: toRem(15);
  }
}
</style>

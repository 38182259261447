<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-4 mx-auto">
          <!-- PAYSTACK HOLDER  -->
          <div class="paystack-holder"></div>

          <paystack
            :amount="getAmount"
            :email="getEmailAddress"
            :paystackkey="paystack_pub_key"
            :reference="reference"
            :callback="callback"
            :channels="['card', 'bank_transfer']"
            :close="close"
            :embed="false"
            class="position-absolute invisible"
            ref="paystackBtn"
          >
          </paystack>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { PAYSTACK_PUBLIC_KEY } from "@/env";
import { mapGetters, mapActions } from "vuex";
import paystack from "vue-paystack";

export default {
  name: "makePayment",

  metaInfo: {
    title: "Make Payment",
  },

  components: {
    paystack,
  },

  computed: {
    ...mapGetters({
      getAuthToken: "auth/getAuthToken",
      getAuthUser: "auth/getAuthUser",
    }),

    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    getEmailAddress() {
      return `${this.getAuthUser?.phone}@class54.com`;
    },

    getAmount() {
      return +this.selected_plan?.currencies[0]?.amount * 100;
    },
  },

  created() {
    this.fetchSubscriptions();
    this.getSelectedPlan();
  },

  mounted() {
    // this.$refs.paystackBtn.$el.click();
  },

  data() {
    return {
      paystack_pub_key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY_PROD,
      selected_plan: {},
      subscription_data: [],
    };
  },

  methods: {
    ...mapActions({
      activateMobileSubscription:
        "storeSubscription/activateMobileSubscription",
      fetchAllSubscriptions: "storeSubscription/fetchAllSubscriptions",
    }),

    async fetchSubscriptions() {
      const subscriptions = await this.fetchAllSubscriptions();
      this.subscription_data = subscriptions.data;

      await this.getSelectedPlan();
    },

    async getSelectedPlan() {
      this.selected_plan = this.subscription_data.find(
        (plan) =>
          plan.name.toLowerCase() === this.$route.params.plan.toLowerCase()
      );

      if (Object.keys(this.selected_plan).length)
        this.$refs.paystackBtn.$el.click();
    },

    callback(response) {
      let payload = {
        subscription_plan_id: this.selected_plan.id.toString(),
        phone: this.getAuthUser?.phone,
        currency_id: "1",
        amount: (this.getAmount / 100).toString(),
        transaction_ref: this.reference,
      };

      if (response.status === "success") {
        this.$bus.$emit("show-page-loader");

        this.activateMobileSubscription(payload).then((res) => {
          if (res?.status_code === 200) {
            this.$bus.$emit("hide-page-loader");

            this.$router.push({
              name: "SubscriptionSuccess",
              query: { ref: this.reference },
            });
          }
          // FAILED RESPONSE
          else {
            this.$bus.$emit("hide-page-loader");
            this.$router.push({ name: "SubscriptionError" });
          }
        });
      }

      // FAILED TRANSACTION
      else {
        setTimeout(() => {
          this.$bus.$emit("hide-page-loader");
          this.$router.push({ name: "SubscriptionError" });
        });
      }
    },

    close() {
      location.href = "/select-subscription";
    },
  },
};
</script>

<style lang="scss" scoped>
.paystack-holder {
  height: 40vh;
}
</style>

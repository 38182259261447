import { $serviceApi as api } from "@/services/apiHelper";

const routes = {
  get_countries: "/admin/countries",
};

export default {
  async getCountries() {
    return api.adminService().fetch(routes.get_countries);
  },

  updateMobileDownloadState({ commit }) {
    commit("UPDATE_MOBILE_DOWNLOAD");
  },
};

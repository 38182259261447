<template>
  <div class="privacy-hero-banner brand-sky-blue-bg">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="privacy-content mx-auto">
        <!-- LEFT -->
        <div
          class="left-section"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="title-text brand-dark-blue font-weight-700">
            Privacy Policy
          </div>
          <div class="meta-text brand-dark-blue">
            Last updated September 2021.
          </div>
        </div>

        <!-- RIGHT -->
        <div class="right-section">
          <img
            v-lazy="staticAsset('EmptyState.png', 'landing')"
            alt=""
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="80"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyHeroBanner",
};
</script>

<style lang="scss" scoped>
.privacy-hero-banner {
  padding: toRem(64) toRem(20) toRem(80);

  @include breakpoint-down(lg) {
    padding: toRem(64) 0 toRem(70);
  }

  .privacy-content {
    @include flex-row-between-nowrap;

    @include breakpoint-down(sm) {
      @include flex-row-between-wrap;
    }

    .left-section {
      width: 50%;

      @include breakpoint-down(sm) {
        width: 100%;
      }

      .title-text {
        @include font-height(48, 58);
        margin-bottom: toRem(16);

        @include breakpoint-down(xl) {
          @include font-height(42, 50);
        }

        @include breakpoint-down(lg) {
          @include font-height(38, 46);
          margin-bottom: toRem(12);
        }

        @include breakpoint-down(xs) {
          @include font-height(35, 40);
          margin-bottom: toRem(10);
        }
      }

      .meta-text {
        @include font-height(16, 26);

        @include breakpoint-down(xl) {
          @include font-height(14, 22);
        }
      }
    }

    .right-section {
      width: 50%;

      @include breakpoint-down(sm) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>

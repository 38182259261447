<template>
  <div class="pricing-page">
    <!-- PRICING HERO BANNER  -->
    <pricing-hero-banner />

    <!-- PRICING DATA  -->
    <pricing-data />

    <!-- FAQ SECTION  -->
    <faq-section :faqs="faqs" />
  </div>
</template>

<script>
import pricingHeroBanner from "@/modules/landing/components/pricing-comps/pricing-hero-banner";
import pricingData from "@/modules/landing/components/pricing-comps/pricing-data";
import faqSection from "@/modules/landing/components/about-comps/faq-section";
import { pricingFaqs } from "@/modules/landing/faqs";

export default {
  name: "pricingPage",

  metaInfo: {
    title: "Pricing",
  },

  components: {
    pricingHeroBanner,
    pricingData,
    faqSection,
  },

  data() {
    return {
      faqs: [...pricingFaqs],
    };
  },
};
</script>

<style lang="scss" scoped>
.pricing-page {
}
</style>

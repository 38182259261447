<template>
  <div class="select-wrapper index-9">
    <!-- SELECT INPUT  -->
    <div
      class="select-input form-control pointer smooth-transition"
      ref="selectInput"
      @click="toggleView"
      v-on-clickaway="forceClose"
      :class="option_select ? 'active-select-input' : null"
    >
      <!-- SELECTIONS -->
      <div class="selections" v-if="selected_value">
        <!-- SELECT CARD -->

        <div class="select-text text-capitalize">
          {{ selected_value }}
        </div>
      </div>

      <!-- PLACEHOLDER TEXT -->
      <div class="placeholder-text" v-else>{{ placeholder }}</div>

      <!-- CLOSE BUTTON -->
      <div class="dropdown-icon">
        <div class="wrapper w-100">
          <div
            class="icon icon-caret-down brand-black smooth-transition"
            :class="{ 'rotate-180': option_select }"
          ></div>
        </div>
      </div>
    </div>

    <!-- BOTTOM OPTIONS -->
    <div
      class="select-option-wrapper rounded-4 index-9999 mgt-5 smooth-animation smooth-transition"
      style="z-index: 999999"
      v-if="option_select"
    >
      <!-- SEARCH BAR -->
      <div class="search-bar mgb-4" v-if="allow_search">
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          v-model="search_value"
          @input="$emit('searchItem', search_value)"
        />
        <div class="icon icon-search light-dark-text index-9"></div>
      </div>

      <!-- OPTIONS -->
      <template v-if="options.length">
        <div class="option-scroll-wrapper">
          <div
            class="option-row option-mix-row text-capitalize"
            v-for="(option, index) in options"
            :key="index"
            @click="makeSelection(index)"
          >
            <div class="left">
              {{ option.name }}
            </div>

            <div class="right" v-if="option.logo">
              <div class="avatar">
                <img v-lazy="option.logo" alt="" class="avatar-img" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="no-options grey-text text-left">No result found</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "SingleDataSelect",

  mixins: [clickaway],

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    pre_select: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: "Select One",
    },
    allow_search: {
      type: Boolean,
      default: false,
    },
    clear_selection: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      option_select: false,
      selected_value: null,
      search_value: null,
    };
  },

  watch: {
    clear_selection: {
      handler(value) {
        if (value) this.selected_value = null;
      },
    },

    pre_select: {
      handler(value) {
        if (Object.keys(value).length)
          this.selected_value = value.name || value.abbreviation;
      },
    },
  },

  methods: {
    toggleView() {
      this.option_select = !this.option_select;
    },

    forceClose() {
      this.option_select = false;
    },

    showSelection(data) {
      // let selected_data = this.options.find((item) => item.id === id);
      this.selected_value = data.name;
    },

    makeSelection(index) {
      this.selected_value = this.options[index].name;
      this.$emit("selectedOption", this.options[index].id);
      this.forceClose();
    },
  },
};
</script>

<style></style>

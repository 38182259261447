import Vue from "vue";

import { renderAssets } from "@/services/assetHelper";
import { $serviceDate as $date } from "@/services/dateHelper";

Vue.mixin(renderAssets);

Vue.mixin({
  computed: {
    $date: () => $date,
  },
});

Vue.prototype.$bus = new Vue();

const landingRoutes = [
  {
    path: "/home",
    alias: "/",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/layout/layout-with-footer-cta"),

    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/modules/landing/pages/home"),
        meta: {
          title: "Home",
        },
      },

      {
        path: "/about",
        name: "About",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/modules/landing/pages/about"
          ),
        meta: {
          title: "About",
        },
      },

      {
        path: "/pricing",
        name: "Pricing",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/modules/landing/pages/pricing"
          ),
        meta: {
          title: "Pricing",
        },
      },

      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () =>
          import(
            /* webpackChunkName: "privacy policy" */ "@/modules/landing/pages/privacy-policy"
          ),
        meta: {
          title: "Privacy Policy",
        },
      },
    ],
  },
];

export default landingRoutes;

<template>
  <div class="contact-section brand-white-bg" id="contact">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="contact-content mx-auto">
        <!-- TITLE TEXT  -->
        <div
          class="title-text font-weight-700"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Contact information
        </div>

        <!-- ITEMS  -->
        <div
          class="item-text"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-delay="90"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="icon icon-phone mgr-10"></div>
          <div class="text">+234 816 704 2330</div>
        </div>

        <div
          class="item-text"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-delay="130"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="icon icon-email mgr-10"></div>
          <div class="text">info@class54.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactSection",
};
</script>

<style lang="scss" scoped>
.contact-section {
  padding: toRem(78) 0 toRem(40);

  .contact-content {
    @include flex-column-center;
    color: $blackish-grey;

    .title-text {
      @include font-height(22, 30);
      margin-bottom: toRem(18);
      text-align: center;
    }

    .item-text {
      @include flex-row-center-nowrap;
      margin-bottom: toRem(18);

      .icon {
        position: relative;
        font-size: toRem(18);
      }

      .text {
        font-size: toRem(15);
        font-weight: 600;
      }
    }
  }
}
</style>

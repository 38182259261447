<template>
  <div class="privacy-policy-page">
    <!-- PRIVACY HERO BANNER  -->
    <privacy-hero-banner />

    <!-- PRIVACY CONTENT  -->
    <privacy-content />
  </div>
</template>

<script>
import privacyHeroBanner from "@/modules/landing/components/privacy-comps/privacy-hero-banner";
import privacyContent from "@/modules/landing/components/privacy-comps/privacy-content";

export default {
  name: "privacyPolicyPage",

  metaInfo: {
    title: "Privacy Policy",
  },

  components: {
    privacyHeroBanner,
    privacyContent,
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy-page {
}
</style>

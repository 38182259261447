<template>
  <section class="new-exam-list-section brand-white-bg">
    <div class="container px-3 px-sm-3 px-md-3 px-xl-2">
      <div class="exam-content mx-auto">
        <!-- LEFT SECTION -->
        <div class="left-pane">
          <!-- TITLE TEXT -->
          <div class="title-text brand-dark-blue font-weight-700">
            Achieve top scores in important examinations
          </div>

          <!-- META TEXT -->
          <div class="meta-text blackish-grey">
            Our members have achieved outstanding results, with many exceeding
            their expected scores in JAMB, WAEC, and POST UTME exams. We're
            confident that we can help you achieve your goals too.
          </div>
        </div>

        <!-- RIGHT SECTION -->
        <div class="right-pane">
          <new-exam-card
            v-for="(exam, index) in exams"
            :key="index"
            :exam_info="exam"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import newExamCard from "@/modules/landing/components/home-comps/new-exam-card";

export default {
  name: "newExamListSection",

  components: {
    newExamCard,
  },

  data: () => ({
    exams: [
      {
        icon: "",
        icon_text: "250+",
        icon_img: "",
        text: "JAMB",
        description:
          "Practise with over 20 years of UTME past questions and standard questions, detailed answers and explanation in real exam conditions.",
      },
      {
        icon: "",
        icon_text: "",
        icon_img: "SchoolIcon.png",
        text: "POST UTME",
        description:
          "Practise past questions over 36 institutions in Nigeria. This includes Universities, Polytechnics and Colleges of Education",
      },
      {
        icon: "icon-exam-score",
        icon_text: "",
        icon_img: "",
        text: "WAEC",
        description:
          "Practise with over 19 years of past questions and standard questions, detailed answers and explanation just like the real exams.",
      },
    ],
  }),
};
</script>

<style lang="scss">
.new-exam-list-section {
  padding: toRem(110) 0 toRem(100);

  @include breakpoint-down(md) {
    padding: toRem(70) 0;
  }

  @include breakpoint-down(sm) {
    padding: toRem(60) 0;
  }

  .exam-content {
    @include flex-row-between-wrap;
    align-items: flex-start;

    .left-pane {
      width: 36%;

      @include breakpoint-down(lg) {
        width: 40%;
      }

      @include breakpoint-down(md) {
        width: 100%;
      }

      .title-text {
        @include font-height(52, 67);
        margin-bottom: toRem(27);

        @include breakpoint-down(xl) {
          @include font-height(46, 58);
        }

        @include breakpoint-custom-down(880) {
          @include font-height(40, 55);
        }

        @include breakpoint-down(md) {
          @include font-height(36, 48);
          margin-bottom: toRem(18);
          text-align: center;
        }

        @include breakpoint-down(sm) {
          @include font-height(33, 43);
        }

        @include breakpoint-down(xs) {
          @include font-height(29, 38);
        }
      }

      .meta-text {
        @include font-height(17.5, 30);
        width: 100%;

        @include breakpoint-down(xl) {
          @include font-height(16.45, 28);
          width: 90%;
        }

        @include breakpoint-down(md) {
          margin-bottom: toRem(32);
          text-align: center;
          width: 100%;
        }

        @include breakpoint-down(md) {
          @include font-height(15.5, 24);
        }

        @include breakpoint-down(xs) {
          @include font-height(14.75, 22);
        }
      }
    }

    .right-pane {
      @include flex-column-center;
      row-gap: toRem(24);
      width: 56%;

      @include breakpoint-down(xl) {
        row-gap: toRem(20);
      }

      @include breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
</style>
